.aboutSlider .slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: #fff;
    padding: 28px;
	margin: 20px;
	height: 80px;
}

.slick-slider,
.projectsContainerslides{
	width: 100%;
}

.projectsContainerslides .slick-slide > div{
    padding: 0px;
	margin: 0px;
	height: 100%;
    display: flex;
}
.partnersSeriousContainerMobile .slick-slide > div{
    padding: 5px;
	box-shadow: none;
	/* margin: 0px 10px; */
}

@media screen and (max-width: 1024px) {
	.slick-slide > div {
		padding: 20px;
		margin: 15px 30px;
	}

	.slick-list {
		padding:0 20% 0 0 !important;
	}
}

.row {
    display: flex;
    overflow: hidden;
}
.itemblog{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.links{
    width: 20%;
    display: flex;
}


@media only screen and (max-width: 768px) {

.links{
    width: 100%;
    display: flex;
}

}

@media only screen and (min-width: 769px) and (max-width: 1200px) {

    .links{
        width: 33.33%;
        display: flex;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .links{
        width: 25%;
        display: flex;
    }
}
.container {
  width: 100%;
  display: flex;
  height: 100%;
  min-height: 100vh;
  background-image: url('../../assets/contact/bgr_cra.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.backgroundImg{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.mapContainer {
  width: 25%;
  height: 100vh;
  background-color: #fff;
  filter: grayscale(1);
}
.mapContainer:hover{
  filter: grayscale(0) !important;
}

.contentContainer {
  width: 100%;
  display: flex;
  margin-left: 38px;
  margin-top: 175px;
}

.contentContainer > div {
  width: 30%;
}

.title{
  font-size: 14px;
  font-family: "SofiaPro-Semibold";
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 5px;
}

.text{
  font-size: 16px;
	font-family: "Montserrat-Light";
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
}

.middleContainer {
  position: relative;
  width: 80%;
  height: 100vh;
  margin-left: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formContainer form > div{
  display: flex;
  justify-content: space-between;
}

.formContainer form > div > div{
  width: 49%;
  display: flex;
  flex-direction: column;
}

.earthContainer {
  width: 58%;
  height: 100%;
  position: absolute;
  left: -36vw;
  overflow: hidden;
  z-index: 20;
}

.earth {
  width: 100%;
  height: 100%;
  margin-top: 32%;
}

.backgroundImage {
  width: 30%;
  position: fixed;
  z-index: -1;
  object-fit: cover;
  right: 25%;
  align-self: center;
}

.textContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.formi{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.formContainer {
  display: flex;
  flex-direction: column;
}

.formContainer{
  width: 380px !important;
}

.formContainer .cont{
  margin: 0 30px;
}

.input {
  border-color: #1f1f1f;
  border-width: 1px;
  border-style: solid;
  padding: 9px 5px;
  border-radius: 4px;
  background-color: #000;
  font-size: 14px;
}

::placeholder {
  color: #fff;
  opacity: 0.5;
  font-family: "Montserrat-Light";
  font-size: 13px;
  font-weight: 100;
}

.textarea {
  border-color: #1f1f1f;
  border-width: 1px;
  border-style: solid;
  padding: 8px;
  border-radius: 4px;
  background-color: #000;
  min-height: 60px;
  font-size: 14px;
  resize: none;
  color: #fff;
}

.textareaContainer{
  flex-direction: column;
}

.submitContainer {
  display: flex;
  border-color: #1f1f1f;
  border-width: 1px;
  border-style: solid;
  background-color: #000;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
}
.submit {
  width: 265px;
  text-align: left;
  background-color: #000;
  border: 0;
  color: #fff;
  font-family: "Montserrat-Light";
}

.submitIcon {
  width: 20px;
  object-fit: contain;
}

.contactButton {
  background-color: #58c2d7;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  font-family: 'Montserrat-Light';
  font-size: 13px;
  border: none;
  padding:12px 22px;
}

.contactButtontIcon {
  width: 15px;
  object-fit: contain;
}

.astronautImg{
  position: relative;
  top: -30%; 
  right: 25px;
}

.carersContent h4{
  font-size: 14px;
  color: #fff;
  font-family: 'SofiaPro-Semibold';
  font-weight: 100;
  margin: 0 0 5px 0;
}

.carersContent h5{
  font-size: 20px;
  font-family: 'Montserrat-Light';
  font-weight: 100;
  margin: 0 0 10px 0;
}


.carersContent p{
  font-size: 11px;
  font-weight: 100;
  width: 55%;
  line-height: 1.2;
  font-family: 'Montserrat-Light';
}
.carersContent{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 57px;
  margin-top: -41px;
}

.socialIcons img{
  font-size: 20px;
  align-self:center;
  margin: 0 auto;
}

.socialIcons{
  text-align:center;
  float: left;
  width: 27px;
  height: 27px;
  border: 1px solid rgba(225, 225, 225, .2);
  border-radius: 100%;
  margin-right: 7px; /*space between*/
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  }

  .carersText{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .carersInfo{
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
  }
  .speechbubble {
    position: relative;
    background: #000000;
    background-image:
      linear-gradient(
        to bottom, 
        #18c3da, #e806f0
      );
    border-radius: 2.4em;
    width: max-content;
    height: 100px;
    top: -201.128px;
    right: 57px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .speechbubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 31px solid transparent;
    border-top-color: #e806f0;
    border-bottom: 0;
    border-left: 0;
    margin-left: -15.5px;
    margin-bottom: -31px;
  }
  .logo1up{
    height: 100%;
      width: 25%;
      object-fit: contain;
  }
  .desc1up{
    color: #ffffff;
    font-size: 20px;
    padding-right: 20px;
    margin: 0;
  }
  .getDirection{
    height: 20px;
    filter: invert(1) brightness(100%) sepia(100%) saturate(100%);
  
  }
  .direction{
    display: flex;
      align-items: center;
      position: relative;
      right: 100px;
      /* margin-bottom: 62px; */
      margin-top: -60px;
      margin-right: -100px;
      margin-left: 50px;
  }
  
  @media only screen and (max-width:360px)  and (max-height:650px){
 
  
  .speechbubble {
    position: relative;
    background: #000000;
    background-image:
      linear-gradient(
        to bottom, 
        #18c3da, #e806f0
      );
    border-radius: 2.4em;
    width: max-content;
      height: 100px;
    top: -201.128px;
    right: 57px;
    display: flex;
      justify-content: space-around;
      align-items: center;
  }
  
  .speechbubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 31px solid transparent;
    border-top-color: #e806f0;
    border-bottom: 0;
    border-left: 0;
    margin-left: -15.5px;
    margin-bottom: -31px;
  }

  .logo1up{
    height: 100%;
    width: 25%;
    object-fit: contain;
  }
  .desc1up{
    color: #ffffff;
    font-size: 20px;
    padding-right: 20px;
    margin: 0;
  }
  .getDirection{
    height: 20px;
  }
  .direction{
    display: flex;
      align-items: center;
      position: relative;
      right: 100px;
      margin-top: -60px;
      margin-right: -100px;
      margin-left: 50px;
  }
    .middleContainer{
      top: -303px !important;
    }
    .mapContainer{
      margin-top: -243px !important;
      filter: grayscale(0);
    }
   
    .input{
      width: 60%;
    }
    .textareaContainer{
      width: 69%;
    }
    .submitContainer{
      width: 64.5% !important;
    }
    
    .earth :nth-child(1){
      transform: rotate(90deg);
      width: 395px !important;
      height: 800px !important;
      margin-left: 85% !important;
    }
    }
  
@media only screen and (max-width: 1024px) and (max-height:1200px) {
  .socialWeb{
    display: none;
  }
  .first{
    display: flex;
  }
  .container {
    flex-direction: column;
    overflow-x: hidden;
    background-image:none;
  }
  .backgroundImg{
    display: none;
  }
 
  .earth {
    margin-top: -34%;
    position: relative;
    margin-left: -10%;
  }
 
  .earth :nth-child(1){
    transform: rotate(90deg);
    width: 100% !important;
    height: 100% !important;
    margin-left: 50% !important;
    margin-top: 15% !important;
  }

  .earthContainer {
    width: 80vh;
    height: 800px;
    position: relative;
    overflow: hidden;
    z-index: 20;
    top: 0px;
    display: flex;
    left: -7vw;
    top: -360px;
  }
  
  .astronautImg{
    display: none;
  }

  .astronautImgMobile{
    position: relative;
    top: 169px!important;
    right: 33%;
    width: 68% !important;
    height: 60%;
    margin: 0 auto;
    transform: scaleX(-1);
    z-index: 23;
    object-fit: contain;
  }
  .companyInfo{
    display: none;
  }
  .middleContainer {
    position: relative;
    width: 100%;
    height: auto;
    margin-left: 0;
    top: -477px;
  }
  .input {
    padding: 15px;
    line-height: 1.2;
  }
  .mapContainer {
    width: 100%;
    height: 300px;
    background-color: #fff;
    margin-top: -398px;
    filter: grayscale(0);
  }
  .formContainer{
    display: flex;
  }
  .formContainer form > div{
    display: flex;
    flex-direction: column;
  }
  .formContainer form > div.submitContainer{
    flex-direction: row;
    padding: 0 15px 0 0;
  }
  .formContainer form > div > div{
    width: 100%;
  }
  .contentContainer {
    width: 100%;
    display: flex;
    margin-top: -65px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 110px;
  }
  .contentContainer > div {
    width: 100%;
  }
  .contentContainer > div:first-child {
    padding: 30px;
  }
  .astronautImg{
    position: relative;
    top: 0;
    right: 0;
    width: 60%;
    margin: 0 auto;
    transform: scaleX(-1)
  }
  .carersContent{
    margin-left: 30px;
    margin-right: 30px;
  }

  .carersContent p{
    font-size: 11px;
    font-weight: 100;
    width: 100%;
    line-height: 1.2;
    font-family: 'Montserrat-Light';
  }
  .imgContainer{
    display: flex;
  }
  .cont{
    margin: 0px 0px !important;
  }
  .input{
    width: 70%;
  }
  .textareaContainer{
    width: 79%;
    margin-top: 15px !important;
  }
  .submitContainer{
    width: 74.5% !important;
  }
  .textContainer_1{
    background-color: #58c2d7;
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 12px 0px 10px 20px;
    position: relative;
    top: -23px;
  }
  .textContainer{
    margin-top: 0;
    margin-left: 35px;
  }
  .clock{
    display: flex;
    width: 74%;
  }
  .mail{
    display: flex;
    width: 43%;
  }
  .iconText{
    font-family: "Montserrat-Light";
  }
  .socialIcons img{
    font-size: 20px;
    align-self:center;
    margin: 0 auto;
  }
  .socialIcons{
    text-align:center;
	  float: left;
	  width: 27px;
	  height: 27px;
	  border: 1px solid #9b9898;
	  border-radius: 100%;
	  margin-right: 7px; /*space between*/
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .carersInfo{
    margin-left: 10px;
  }
  .socialButtonsMobile{
    padding: 10px 0 !important;
  }
  .contentContainer{
    margin-left: 26px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width:1400px) {
  .mapContainer{
    display: none;
  }
  .container{
    background-image: none;
  }
  .astronautImg{
    top: -24%;
    right: -19px;
  }
  .formContainer form > div > div{
    width: 47%;
  }
  .middleContainer{
    margin-left: -18vw;
  }
  .earthContainer{
    overflow: visible;
    position: static;
    margin-left: -20%;
  } 
  .earth{
    margin-left: -20%;
    display: none;
  }
}



@media only screen and (max-width: 380px) and (max-height:690px) and (min-height:500px){
  .input{
    width: 70%;
  }
  .textareaContainer{
    width: 79%;
    margin-top: 15px !important;
  }
  .submitContainer{
    width: 74.5% !important;
  }

  .middleContainer{
    top: -464px !important;
  }
  .mapContainer{
    margin-top: -386px !important;
  }

  .earth :nth-child(1){
    transform: rotate(90deg);
    margin-left: 73% !important;
  }
  .earth{
    margin-top: -30%;
  }
  .mapContainer {
    filter: grayscale(0);
  }
  .mapContainer:hover{
    filter: grayscale(0) !important;
  }
}

@media only screen and (max-height: 930px) and (min-height:800px) and (max-width:420px) {
  .input{
    width: 80%;
  }
  .textareaContainer{
    width: 89%;
    margin-top: 15px !important;
  }
  .submitContainer{
    width: 84.5% !important;
  }
  .astronautImg{
    margin-top: 40px;
  }

  .earth :nth-child(1){
    transform: rotate(90deg);
    width: 100% !important;
    height: 100% !important;
    margin-left: 50% !important;
    margin-top: 15% !important;
  }
  .mapContainer {
    filter: grayscale(0);
  }
  .mapContainer:hover{
    filter: grayscale(0) !important;
  }
}


@media only screen and (max-width:350px) and (max-height:490px){
	
	.middleContainer{
	  top: -303px !important;
	}
  .mapContainer:hover{
    filter: grayscale(0) !important;
  }
	.mapContainer{
	  margin-top: -243px !important;
    filter: grayscale(0);
	}
	.input{
	  width: 60%;
	}
	.textareaContainer{
	  width: 69%;
	}
	.submitContainer{
	  width: 64.5% !important;
	}
  .earth :nth-child(1){
    transform: rotate(90deg);
    width: 395px !important;
    height: 800px !important;
    margin-left: 98% !important;
    margin-top: -17% !important;
  }

  .earthContainer{
    left:0
  }
 
  }

.container {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/background.png");
  background-size: 100vw 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.astrounautsImage {
  width: 80vw;
  height: 70vh;
  object-fit: contain;
  position: absolute;
  z-index: 20;
  align-self: center;
  top: 0;
}
/* .astrounautsImage:hover {
  animation: shake 15s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
} */

.contentContainer {
  /* width: 100%;
  height: 100vh;
  background-image: url("../../assets/home/bg1.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center; */
  width: 100%;
  /* height: 100vh; */
  min-height: 100%;
  background-image: url("../../assets/images/home/background-desktop.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
}

.linkContainer {
  width: 200px;
  height: 200px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.linkText {
  color: #fff;
  font-family: 'Montserrat-Light';
  font-size: 22px;
  
}

.linkImg {
  width: 30px;
  object-fit: contain;
}

.centerText{
  position: absolute;
  top: 40%;
  color: #fff;
  font-size: 33px;
  font-family: "SofiaPro-Medium";
  max-width: 450px;
  text-align: center;
  z-index: 0;
}
.videobackground{
  /* width: fit-content;
  position: absolute;
  margin: 0;
  display: flex;
  right: 15%;
  height: 35%; */
  width: 100%;
    position: absolute;
    margin: 0;
    display: flex;
    right: 15%;
    height: 100;
    z-index: -1;
    position: fixed;
    bottom: 0;
    right: 0;
}

@media only screen  and (max-width:375px){
  .backgroundx2{
    width: 100%;
    justify-content: center; 
    animation: fadeBackground 10s;
    animation-fill-mode: both;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 82%;
    background-position: center 33%;
    display: flex;
    flex-direction: column;
    overflow: initial !important;
    justify-content: flex-start;
  }
}

@media only screen  and (max-width:768px){
  .contentContainer {
     width: 100%;
    justify-content: center; 
    background-image: url("../../assets/images/home/background-mobile.png");
    display: flex;
    justify-content: center;
    background-size: 100% 50vh;
    min-height: 50%;

  }
  .centerText{
    position: initial;
    color: #fff;
    font-size: 20px;
    font-family: "SofiaPro-Medium";
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    margin: 0;
    padding-bottom: 45px;
  }
  .astrounautsImage {
    width: 90vw;
    height: 50vh;
    object-fit: contain;
    position: absolute;
    z-index: 20;
    align-self: center;
    top: 0;
  }
  .backgroundx2{
    width: 100%;
    justify-content: center; 
    animation: fadeBackground 10s;
    animation-fill-mode: both;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 82%;
    background-position: center 33%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
  }
  @keyframes fadeBackground {
    0% { background-image: url("../../assets/images/home/background-pink.png"); }
    50% {background-image: url("../../assets/images/home/background-blue.png");}
    100% { background-image: url("../../assets/images/home/background-pink.png"); }
  }
  .astrounautImage{
    width: 60%;
    height: 51%;
    position: relative;
    left: 20%;
    object-fit: contain;
    margin-top: 10%;
    animation: move 3s infinite linear;
  }
  .linkText{
    color: #fff;
    font-family: 'Montserrat-Light';
    font-size: 19px;
    margin: 0;
  }
  .linkContainer {
    width: 100%;
    height: auto;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  .arrowlottie path[fill = "rgb(255,255,255)"]{
    fill: rgb(15, 163, 221) !important;
  }
  @keyframes move {
    0% {
    transform: rotate(0deg) translateX(3px) rotate(0deg);
    }
    100% {
    transform: rotate(360deg) translateX(3px) rotate(-360deg);
    }
   }
}

@media only screen and (min-width: 869px) and (max-width: 1268px) {

  .contentContainer {
    /* width: 100%;
    height: 100vh;
    background-image: url("../../assets/home/bg1.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center; */
    width: 100%;
     /* height: 250vh;  */
     /* min-height: 100%; */
    background-image: url("../../assets/images/home/background-desktop.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    
  }
}
@media only screen and (min-width: 1268px) and (max-width: 1600px) {

  .contentContainer {
    /* width: 100%;
    height: 100vh;
    background-image: url("../../assets/home/bg1.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center; */
    width: 100%;
     /* height: 250vh;  */
    background-image: url("../../assets/images/home/background-desktop.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
   
  }
  .linkContainer{
    width: 100%;
    height: auto;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  .centerText{
    position: initial;
    top: 0;
    color: #fff;
    font-size: 33px;
    font-family: "SofiaPro-Medium";
    max-width: 450px;
    text-align: center;
    z-index: 0;
  }
}
.header {
    padding-bottom: 50px;
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: space-around;
    padding-top: 74px;
}

.container p {
	color: #fff;
}

h2{
	color: #fff;
}

.content{
    padding-top: 0;
}

.header h2{
    font-size: 24px;
    font-family: "SofiaPro-Light";
    margin-top: 4%;
    margin: 0;
    color: #58C2D7;
}

.tab{
    position: relative;
    /* margin-left: 3%; */
    text-align: center;
    /* padding-top: 2.5%; */
    margin-right: 20%;
}

.button{
	background-color: transparent;
    border: none;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
	cursor: pointer;
    padding-left: 0;
    padding-right: 30px;
}

.customrow{
    display: flex;
    margin-right: 5px;
    padding-bottom: 18px;
    padding-top: 10px;
    
}

.col4{
    display: flex;
    flex-flow: column wrap;
    /* align-content: space-between; */
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
}

.rectangle{
  width: 300px !important;
  height: 400px !important;
}

.square{
    width: 300px !important;
    height: 300px !important;
}
.col2{
    margin-right: 5px;
    padding-bottom: 41%;
    margin-top: 35%;
}

.images{
    margin-bottom: 10px;
    cursor: pointer;
    max-width: 344px;
    max-height: 600px;

}


@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -10%, 0);
    }
    
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

@media screen and (max-width: 768px) {
    .header {
        display: block;
        margin-left: 0px;
        padding-top: 137px;
        padding-bottom: 0;
    }
    .projectport{
    display: flex;
    flex-direction: row;
    height: auto;
    flex-wrap: wrap;
    width: 100%;
    }
    .header h2{
        font-size: 25px;
        text-align: center;
    }
    .customrow{
        display: block;
        margin-left: 0;
        width: 100%;
        padding-bottom: 0;
    }
    .col4{
        width: 50%;
        margin-right: 0px;
        text-align: center;
        display: flex;
        margin: 0;
        padding: 0%;
    }
    .tab{
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #321e05;
        border-top: 1px solid #321e05;
        padding-top: 20px;
        margin-top: 18px;
    }   
    
    .col2{
        margin-right: 5px;
        margin-bottom: 10px;
    }
    .images{
        margin-bottom: 0;
        width: 99%;
        padding: 0;
        object-fit: cover;
    }
    .images img{
        width: 100% !important;
    }
    .button{
        padding-right: 0;
        text-transform: none;
        font-size: 15px;
    }
    .content{
        padding-bottom: 0;
    }
  }
  @media only screen and (min-width: 1361px) and (max-width: 1600px) {
    .header h2{
        margin-left: 10%;
    }
  }
  @media only screen and (min-width: 770px) and (max-width: 1360px) {
    .header h2{
        font-size: 24px;
        font-family: "SofiaPro-Light";
        margin-top: 4%;
        margin: 0;
        color: #58C2D7;
        margin-left: 100px;
    }
    .tab{
        margin-right: 25%;
    }
  }
  @media only screen and (min-width: 770px) and (max-width: 1020px) {
    .header h2{
        font-size: 24px;
        font-family: "SofiaPro-Light";
        margin-top: 4%;
        margin: 0;
        color: #58C2D7;
        margin-left: 130px;
    }
    .tab{
        margin-right: 27%;
    }
  }
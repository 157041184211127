.container{
  display: flex;
  flex-direction: column;
}
@keyframes drop-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
   

.dropin {
  animation: drop-in 1s ease 0.5s backwards;
    
}

.dropinn {
  animation: drop-in 1200ms ease 1s backwards;
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.firstcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100vh;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
  }
@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-300px);
    }
    to {
      opacity: 1;
    }
  }
.loading{
  position: relative;
  top: 34%;
  background-color:#58c3d7;
  width: 360px;
  height: 165px;
  text-align: center;
  animation: drop-in 1200ms ease 1.5s backwards;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading p {
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.secondcontainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.minisecondcontainer{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.photo {
  position: relative;
  left: 12%;
  /* top: 126px; */
  animation: fadeInRight 5s ease-in-out ;
}

.desc :nth-child(2){
  font-size: 28px;
  text-align: left;
  width: 30%;
  position: relative;
  left: -125px;
}
.photo1 img {
  width: 450px !important;
  position: relative;
  left: -74%;
  /* top: -17px; */
}

.secondLine{
  width: 378px;
  height: 119vh;
  background-color:#58c3d7;
  position: relative;
  left: 25.15%;
}
.thirdLine{
  width: 378px;
  height: 119vh;
  background-color:#58c3d7;
  position: relative;
  left: 25.15%;
}
.desc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left:  260px;
  animation: fadeInLeft 5s ease-in-out ;
}

.desc1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* animation: fadeInLeft 5s ease-in-out ; */
}
.title{
  font-size: 11px;
  padding: 20px;
  color: #FFFFFF;
  margin: 0 !important;
}

.bigText{
  font-size: 54px;
  width: 50%;
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #93EAFF, #0050A2);
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  display:inline-block;
  font-weight: bold;
}
.orangeBigText{
  font-size: 54px;
  width: 50%;
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #F1B632, #BC4705);
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  display:inline-block;
  font-weight: 900;
  position: relative;
  left: -151px;
}

.thirdcontainer{
  display: flex;
  flex-direction: column;
}
.minithirdcontainer{
  display: flex;
  align-items: center;
  height: 100%;
}
.photo_3 {
  position: relative;
  left: 12%;
  /* top: 126px; */
  animation: fadeInLeft 5s ease-in-out ;
}
.desc_3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left:  260px;
  animation: fadeInRight 5s ease-in-out ;
}

.forthcontainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/porsche/crazy/bg_03.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.forthcontainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/porsche/crazy/bg_03.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.fifthcontainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/porsche/crazy/bg_04@2x.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.buttons_dyqan_service{
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 50px;
  height: 60px;
  width: 270px;
}
.line{
  border:1px solid #000000;
  opacity: 0.2;
}
.buttons_dyqan_service p{
  color: #000000;
  font-size: 18px;
  text-align: center;
}
.buttons_dyqan_service :nth-child(1){
  position: relative;
  left: 10px;
}
.buttons_dyqan_service :nth-child(3){
  position: relative;
  left: -10px;
}
.whiteBigText{
  font-size: 54px;
  text-align: center;
  font-weight: 900;
}
.ikonaPorshi{
  position: relative;
  left: -323px;
}
.sixthcontainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background-image: url("../../assets/porsche/crazy/bg_05@2x.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.desc6{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  left: -17%;
}
.desc6 img{
  position: relative;
  left:-6%;
}

.desc6 p{
  width: 40%;
}
.photo6{
  position: relative;
  left: -7%;
}
.lastPage_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.short_desc{
  position: relative;
  top: 80px;
}
.sharetext{
  text-align: center !important;
  font-size: 11px !important;
}

.projectsContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  position: relative;
  z-index: 1;
  left: 3px;
  top: 147px;
}

.projectLogo {
  object-fit: cover;
  width: 50%;
  height: auto;
  margin-right: 0px;
}
.socialButtona{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.socialButtona button{
  width: 115px;
  height: 38px;
  display: flex;
  flex-direction: row;
  margin-left: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 3px;
}
.socialButtona :nth-child(1){
  background-color: #00419a;
}
.socialButtona :nth-child(2){
  background-color: #00c4ff;
}
.socialButtona :nth-child(3){
  background-color: #ba0039;
}

.lastSquare{
  background-color: #62A5C6;
  width: 360px;
  height: 190px;
  position: relative;
  left: 3px;
  top: 147px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share_buttons{
  position: relative;
  top: 55px;
}
/* SERVICES CONTAINER */
.servicesContainer .slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: none !important;
}
.slick-dots li button::before, 
.servicesContainer{
 background-color: transparent;
 color: #f4f2f7;
}
.servicesContainer{
    width: 100%;
    height: 197px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.servicesContainer .slick-dots{
    position:static;
    margin-top: -5%;
}

/* FEEDBACK CONTAINER */
.feedbackSlider .slick-slide > div{
    box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background-color: transparent !important;
}
.feedbackSlider{
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto;
}

.feedbackSliderMobile .slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: none !important;
}




/* BLOG CONTAINER */
.slideshowContainer{
    margin-top: 50px;
    margin-bottom: 230px;
    width: 1080px;
    margin: 50px auto;
}
.slideshowContainer .slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: none !important;
    overflow: hidden;
}
.slideshowContainer .slick-slide{
    width: 357px !important;
    height: 260px;
}
.slideshowContainer .slick-slide.slick-active{
    width: 357px;
    height: 260px;
    margin: 0 8px;
}

.slideshowContainer .slick-slide.slick-cloned{
    /* width: 350px !important; */
    width: 9%;
    height: 260px;
}
.slideshowContainer .slick-slide > div{
    padding: 0px;
	margin: 0px;
	height: 100%;
    display: flex;
    width: 357px;
}
.slick-dots li button::before, 
.slideshowContainer{
 background-color: transparent;
 color: #f4f2f7;
}
.slick-dots li.slick-active button:before{
    color: rgb(243, 237, 237);
    content: '⦿';
    font-size: 9px;
}
.slick-dots{
    position:static;
    margin-top: -23%;
}
.slick-dots li{
    margin: 0 -2px;
}



@media only screen and (max-width: 800px){
    .slideshowContainer{
        width: 100%;
        height: 197px;
        margin: 40px 0;
        display: flex;
        justify-content: center;
    }
    
    .slideshowContainer .slick-slide{
        width: 267px !important;
        height: 197px !important;
        justify-content: center;
        display: flex;
        position: relative;
        margin: 0;
    
    }
    .slideshowContainer .slick-slide.slick-active{
        margin: 0 74px;
    }
    
    .slideshowContainer .slick-slide.slick-cloned{
        width: 267px !important;
        height: 197px !important;
   
    }
    
     .slideshowContainer .slick-slide > div{
        padding: 0px;
        margin: 0;
        height: 100%;
        display: flex;
        width: 267px;
       
    } 
    
    .slick-dots li button::before, 
    .slideshowContainer{
     background-color: transparent;
     color: #f4f2f7;
    }
    .slick-dots li.slick-active button:before{
        color: rgb(243, 237, 237);
        content: '⦿';
        font-size: 9px;
    }

    .slick-dots{
        position: static;
        margin-top: -48%;
    }

    .servicesContainer{
        width: 100%;
        height: 197px;
        margin: 15px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
 
    .feedbackSlider .slick-slide{
        width: 370px !important;
    }

    .slideshowContainerMobile{
        width: 100%;
        height: 197px;
        margin: 40px auto 60px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;    }

    .slideshowContainerMobile .slick-slide > div{
        box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
        background: none !important;
    }
    .slideshowContainerMobile .slick-slide.slick-active{
        margin: 0 7px;
    }
    .slideshowContainerMobile .slick-dots {
        position: static;
        margin-top: 7px;
    }
    .slideshowContainerMobile .slick-list {
        height: 267px;
    }
 
}



.container {
  background-color: #0000;
  height: 100vh;
  width: 100%;
  /* padding-top: 200px; */
  margin: 0 auto;
  z-index: 1;
  position: relative;
  
}
.description{
  position: absolute;
    left: 26%;
    top: 46%;
    font-size: 30px;
    font-family: 'Montserrat-Light';
    /* padding: 20%; */
    padding-left: 10%;
    text-align: center;
    width: 600px;
    padding-right: 10%;
}

@media screen and (max-width: 768px) {
  [draggable=true] {
    cursor: move;
    }
  
  .resizable {
    overflow: scroll;
    max-width: 500px;
    height: 460px;
    border: 1px solid black;
    min-width: 50px;
    min-height: 50px;
    background-color: skyblue;
  }
  .containermob{
    overflow: hidden;
    
  }
  .descmob{
    color: aliceblue;
    position: absolute;
    top: 55%;
    left: 8%;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "SofiaPro-Light";
  }
  .container{
    height: 100vh;
    width: auto;
  }
}
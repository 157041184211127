@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"), url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "SofiaPro-Light";
  font-weight: normal;
  src: local("SofiaPro-Light"), url(./assets/fonts/SofiaPro-Light.otf) format("opentype");
}

@font-face {
  font-family: "SofiaProLightW";
  font-weight: normal;
  src: local("SofiaPro-Light"), url(./assets/fonts/wof/SofiaProLight.woff) format("opentype");
}

@font-face {
  font-family: "SofiaPro-Regular";
  font-weight: normal;
  src: local("SofiaPro-Regular"), url(./assets/fonts/SofiaPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SofiaPro-Semibold";
  font-weight: normal;
  src: local("SofiaPro-Semibold"), url(./assets/fonts/SofiaPro-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "SofiaPro-Medium";
  font-weight: normal;
  src: local("SofiaPro-Medium"), url(./assets/fonts/SofiaPro-Medium.otf) format("opentype");
}

@font-face {
  font-family: "SofiaPro-Black";
  font-weight: normal;
  src: local("SofiaPro-Black"), url(./assets/fonts/SofiaPro-Black.ttf) format("truetype");
}

@font-face {
  font-family: "SofiaPro-Bold";
  font-weight: normal;
  src: local("SofiaPro-Bold"), url(./assets/fonts/SofiaPro-Bold.otf) format("opentype");
}

body {
  margin: 0;
  background-color: #000;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  font-family: "SofiaPro-Bold";
}




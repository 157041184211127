@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.container {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 160px;
}

.video {
  display: flex;
  justify-content: center;
  position: relative;
  top: 20px;
  flex-direction: column;
}
.voulumeIcon{
  width: 67px !important;
  background: black;
  border-radius: 50%;
}
.volumeContainer{
  position: absolute;
  margin-top: 35px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.video video{
  width: 100%;
  height: auto;
}

.mouseIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  cursor: pointer;
}

/* ABOUT SECTION */

.abouContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
}

.aboutTextContainer {
  position: relative;
  text-align: left;
}
.main{
  font-size: 24px;
  color: #FFFFFF;
  width: 70%;
}
.main p{
  margin-bottom: 12px;
}
.desc p{
  margin-top: 0;
  width: 90%;
}
.desc{
  font-size: 18px;
  color: #FFFFFF;
  width: 70%;
  opacity: 0.5 !important;
  margin-top:0;
}
.aboutEmailContainer {
  padding-left: 35%;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  flex: 1;
}
.email{
  border-bottom: 1px solid rgb(255, 255, 255 ,.2);
  font-size: 20px;
  padding-bottom: 10px;
}

/* SERVICES SECTION */

.servicesContainer {
  display: grid;
  grid-template-columns: 18.5% 18.5% 18.5%;
  justify-content:center;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  margin-top: -0.5%;
  width: 100%;
}
.serviceTextContainer{
  padding-right:15px ;
}
.servicesMain{
  color: #58C2D7;
  font-size: 11px;
  text-transform: uppercase;
  width: 20vw;
}
.servicesTitle{
  color:#FFFFFF;
  font-size: 18px;
}
.servicesDesc{
  color:#FFFFFF;
  font-size: 14px;
  opacity: 0.5 !important;
}
.serviceItemContainer {
  position: relative;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
.serviceItemContainer img {
  width: 60px;
  height: 60px;
  padding-bottom: 10px;
}
.serviceItemImage {
  object-fit: contain;
  margin-bottom: 10px;
  margin-top: 50px;
}

/* IMAGE SECTION */

.imageContainer {
  display: flex;
  margin-top: 2%;
  margin-bottom: 40px;
  width: 80%;
}

.firstImage {
  object-fit: contain;
  width: 100%;
  max-width: 1029.02px;
  height: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  width: 70%;
}
.firstImage:hover{
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  transition: 0.9s;
}
.secondImage {
  object-fit: contain;
  max-width: 449px;
  width: 100%;
  height: auto;
  margin-left: 1.2%;
  width: 30%;
}

/* DESCRIPTION IMG */
.projectContainer{
  color: #FFFFFF;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-evenly;
}
.projectContainerText{
  display: flex;
  padding-right: 0;
}
.projectContainerTitle{
  color: #58C2D7;
  font-size: 38px;
  margin-bottom: -20px;
}
.projectContainerMain{
  font-size: 18px;
  width: 49.1%;
  line-height: 22px;
}
.projectContainerMain2{
  font-size: 18px;
  width: 55%;
  line-height: 22px;
}
.projectContainerDesc{
  font-size: 14px;
  width: 17vw;
  opacity: 0.5 !important;
}

.projectContainerDesc2{
  font-size: 14px;
  width: 12vw;
  opacity: 0.5 !important;
}
.projectContainerPartOne{
  padding-right: 10%;
  line-height: 15px;
}
.projectContainerPartTwo{
  line-height: 15px;
  margin-right: 0;
}

/* BLOG SECTION */

.blogText {
  color: #fff;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  font-size: 32px;
  font-family: 'Sofia Pro Medium';
  margin-top: 15px;
}


.blogTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 37px;
  margin-top: 1.75%;
}

.verticalLine {
  width: 1px;
  height: 88.5px;
  background-color: #fff;
  margin-bottom: 10px;
}

/* Carosel */
.slideshowImg {
  width: 100%;
  height: 100%;
  display:block;
  width: 360px !important;
  height: 260px;
  object-fit: cover;
}

.imgText{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  position:absolute;
  margin-top: -35%;
  margin-left: 3.5%;
  width: 70%;
  padding: 10px;
  opacity: 0 !important;

}
.imgText p {
  margin-bottom: 8px;
}

.imgContainer{
  height: 100% !important;
}
.imgContainer:hover img{
  filter: blur(50px);
  transition: 0.3s ease;
  opacity: 1.7; 
  transform: scale(1.1); 
  -webkit-filter: blur(50px);
  backdrop-filter: brightness(50%) contrast(70%);
}
.imgContainer:hover .imgText{
  position: relative;
  opacity: 1 !important;
  z-index: 999;
  filter: none;
  -webkit-filter: blur(0px);
  transition: .4s;
}


/* FEEDBACK CONTAINER */
.feedbackTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  width: 1080px;
}
.feedContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.feedbacksContainerDesktop {
  display: flex !important; 
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.singlefeedbackContainer {
  max-width: 350px !important;
  height: 200px !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #272727;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 50px;
  margin-left: 6px;
  margin-right: 6px;
  
}

.clientLogo{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feedbackContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.profileImage {
  width: 70px;
  height: 70px;
  object-fit: contain;
  padding: 10px;
}

.feedBackContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  position: relative;
  top: 10px;
}

.startIcon {
  position: relative !important;
  left: 20px;
  margin-right: 5px;
  height: 10px;
}

.stars{
  display: flex !important;
  margin-top: -5% !important;
  padding-bottom: 15px !important;
  position: relative !important;
  left: 10px !important;
}
.starsMobile{
  display: flex !important;
  margin-top: -5% !important;
  padding-bottom: 15px !important;
  position: relative !important;
  left: -10px !important;
}

.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
  padding-bottom: 70px;
  border-bottom: 1px solid #272727;
}

.contactTextContainer {
  width: 50%;
}

.contactButton {
  background-color: #58c2d7;
  color: #fff;
  height: 36px;
  display: flex;
  width: 160px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  font-family: 'SofiaPro-Medium';
  font-size: 13px;
  margin-right: 15.5%;
  margin-bottom: -7%;
  border: none;
  cursor: pointer;
}

.contactButtontIcon {
  width: 20px;
  object-fit: contain;
  margin-right: 5%;
}

.arrowRightIcon {
  width: 20.5px;
  object-fit: contain;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: center;
}
.serviceItemImageMobile{
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
}
.serviceItemImageMobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.servicesMainMobile{
  margin:0;
  width:10%;
  font-size:16px;
}
.servicesTitleMobile{
  text-align: center;
  padding-top: 5px;
  margin:0;
  font-size:16px;

}
.servicesDescMobile{
  text-align: center !important;
  padding-top: 5px!important;
  margin: 0 !important;
  font-size:12px !important;
  opacity:0.5;
  width:70%;
  position: relative;
  left: 15%;

}
.serviceItemImageMobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.feedbacksContainerMobile{
  margin:0 auto;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.startIconMobile{
  position: relative;
  left: 20px;
  margin-right: 5px;
  height: 10px;
}
.starIconContainer{
  display: flex;
  flex-direction: row;
}
.footerHome{
 width:57% !important;
 display: flex;
 justify-content: center;
 align-items: center;
 position: relative;
 left: 21.5%;
}



@media only screen and (max-width: 800px){
  .container{
    background-image: none;
  }
  .video{
    margin: 0 auto;
    width: 100%;
    padding: 0;
    position: static;
    left: 0;
  }
  .video video{
    margin: 0 auto;
    width: 100%;
    padding: 0;
  }
  .abouContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0px;
    left: 0px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    width: 100%;
  }
  .aboutTextContainer {
    position: relative;
    justify-content: center;
    left: 0;
    margin-top: 20px;
  }
  .main{
    font-size: 17px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
  }
  .desc{
    font-size: 18px;
    color: #FFFFFF;
    opacity: 0.5!important;
    text-align: center;
    width: 100%;
  }
  .desc p{
    margin-top: 0;
    width: 60%;
    font-size: 12px;
    text-align: center;
    margin-left: 20%;
  }
  .aboutEmailContainer {
    padding: 0;
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
  }
  .email{
    border-bottom: 2px solid rgb(255, 255, 255 ,.2);
    font-size: 15px;
    padding: 0;
  }
  .imageContainer{
    margin: 0; 
    width: 100%;
    margin-top: 7.5%;
  }
  .firstImage{
    width: 100%;
    height: 208px;
  }
  .secondImage{
    display: none;
  }

  .projectContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-feature-settings: none;
  }
  .projectContainerTitle{
    font-size: 38px;
    margin-top: 0!important;
    padding: 0!important;
    margin-bottom: 0 !important;
  }
  .projectContainerTitle p {
    margin-bottom: 0 !important;
  }
  .projectContainerText{
    display: flex;
    flex-direction: column;
    padding: 0!important;
  }
  

  .projectContainerPartOne{
    margin: 0px 35px;
    padding-right: 0px;
  }
  .projectContainerPartTwo{
    margin: 0px 35px;
    padding-right: 0px;
  }
  .projectContainerDesc{
    width: 100%;
    margin:0 !important;
    padding: 0!important;
  }
  .projectContainerMain2 p{
    width: 140%;
    margin-top: 12px!important;
    margin-bottom: -5px !important;
  }
  .projectContainerMain p{
    width: 140%;
    margin-top: 10px!important;
    margin-bottom: 5px !important;

  }
 .projectContainerDesc2{
    width: 100%;
    margin-top: 0 !important;
  }
  .projectContainerText{
    padding: 10px;
  }
  .servicesContainer{
    display: none;
  }
  .blogText {
    color: #fff;
    text-orientation: mixed;
    writing-mode: horizontal-tb;
    font-size: 20px;
    padding-right: 20px;
  }
  .blogTextContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 0px;
    justify-content: flex-end;
    position:static;
    margin-left: 35px;
    margin-top: 50px;
    margin-bottom: -25px;
  }

  .verticalLine {
    width: 305px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 1px;
    margin-left: 0px;
  }
  
  .contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-right: 0%;
    padding-left: 0%;
    padding-bottom: 50px;
    border-bottom: 1px solid #424242;
    width: 100%;
    margin-top: -2%;
  }
  .contactTextContainer:nth-child(1) {
    font-size: 10px;
    width: 100%;
  }
  .textTitle{
    font-size: 10px;
  }

  .slideshowContainer{
    display: none;
  }
  .contactButton {
    background-color: #58c2d7;
    color: #fff;
    height: 36px;
    display: flex;
    width: 160px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px;
    font-family: 'SofiaPro-Medium';
    font-size: 13px;
    margin-right: 0%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .mouseIcon{
    display: none;
  }

  .feedbacksContainer{
    display: none;
  }
  .slideshowImg {
    background-color: none !important;
    width: 100%;
    height: 100%;
    display:block;
    transition:.3s;
    width: 267px;
    height: 197px;
  }
  .serviceItemContainer{
    display: flex;
    justify-content: center;
  }


  .serviceItemContainerMobile{
    display: flex;
    flex-direction: row;
  }
  .feedbacksContainerMobile{
    margin:0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .feedbackTextContainer {
    display: none;
  }

  .feedbackContentContainer{
    padding: 10px !important;
  }
  .singlefeedbackContainerMobile {
    width: 100%;
    height: 180px;
    border: 1px solid #272727;
    padding: 0px;
    border-radius: 10px;
    width: 100%;
    min-height: 197px;
    max-height: 550px;
    height: 100%;
    margin: 40px 10px;
  }

  .imgContainer img{
    position: relative;
    justify-content: center;
    display: flex;
    left: 66px;
  }
  .imgContainer a{
    position: relative;
    justify-content: center;
    display: flex;
    left: 66px;
  }
  .slideshowImgMobile{
    width: 267px;
    height: 197px;
    background-color: transparent;
  }
  .imgTextMobile{
    display: none;
  }

  .footerHome{
    width:100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0%;
   }
   .slideshowImgMobile{
    object-fit: cover;
   }
   .volumeContainer{
    margin-top: 9px !important;
  }
}




@media only screen and (max-width: 370px){
  .slideshowImgMobile{
    width: 240px;
    height: 197px;
    background-color: transparent;
    object-fit: cover;
  }
  .volumeContainer{
    margin-top: 9px !important;
  }
} 

.slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: #fff;
    padding: 30px;
	margin: 20px;
}

.slick-slider,
.projectsContainerslides{
	width: 100%;
}

.projectsContainerslides .slick-slide > div{
    padding: 0px;
	margin: 0px;
	height: 100%;
    display: flex;
}

.partnersContainerMobile
.projectsPortofolio{
    width: 100%;
}
.slick-slider,
.projectsPortofolio{
	width: 100%;
}
.projectsPortofolio .slick-slide > div{
    background: transparent;
    padding: 0;
    margin: 0;
	width: 313px;
	display: flex;
}
.projectsPortofolio .slick-initialized .slick-slide{
	width: auto !important;
	margin-right: 15px;
}
.projectsPortofolio .slick-track{
	display: flex;
	margin: 0;
	height: 741px;
}
.projectsPortofolio .Gallery_customrow__2SvjW{
	height: 741px;
	width: 100%;
}
.partnersSeriousContainerDesktopCrazy{
	padding: 0px 300px !important;
}
@media screen and (max-width: 1024px) {
	.slick-slide > div {
		padding: 20px;
		margin: 15px 30px;
	}

	.slick-list {
		padding:0 20% 0 0 !important;
	}
}

.footer {
  background-color: transparent;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  height: 60px;
}

.footer_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  padding: 0px 23px 0px 2px;
  /* max-width: 1101px; */
  /* background-size: 1101px; */
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/icons/footer-border.svg");
  height: 100%;
}

.footer_container span {
  padding-right: 3px;
  padding-left: 3px;
  color: #fff;
}

.footer_container > div {
  width: 31.5%;
}

.terms {
  text-align: right;
  font-size: 12px;
  font-family: "SofiaPro-Regular";
  /* width: 31% !important; */
}

.h3 {
  color: #ffffff;
  text-align: left;
  font-size: 12px;
  font-family: "SofiaPro-Semibold";
}

.text {
  color: #ffffff;
}

.logo {
  width: 40px;
  object-fit: contain;
}


@media only screen and (max-width: 800px){
   .terms{
    display: none;
  }

  .footer_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 0px 0px 0px 10px;
    width: 100%;
    background-size: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/Footer.svg");
    height: 100%;
    width: 100%;
  }
  .h3{
    color: #ffffff;
    text-align: left;
    font-size: 13px;
    font-family: "SofiaPro-Light";
    width: 250%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 30%;
  }
  .logo {
    width: 40px;
    object-fit: contain;
    margin: 0;
    padding-top:5px;
  }
  .footer_container > div {
    width: 23%;
  }

}

@media only screen and (min-width: 400px) and (max-width:800px){
  
  .footer_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 0px 10px 0px 20px;
    width: 100%;
    background-size: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/Footer.svg");
    height: 100%;
    width: 100%;
  }
}
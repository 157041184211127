.itemBody{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemBody h1{
	color: #ffffff;
}
.thumbnail {
    width: 100%;
    height: 300px;
    overflow: hidden;
    object-fit: cover;
  }
  
.post {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    height: 100%;
   }
.content{
    color: #ffffffd4;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-family: 'Montserrat-Light';
    line-height: 20px;
    /* padding-right: 30px; */
    padding-bottom: 10px;
    /* margin-right: 80px; */
}

.content p a {
    color: #ffffff;
    
}

.komponente{
    width: 100%;
    padding-right: 110px;
}
ul {
    /* background-color: gray; */
    width: 100%;
    height: 100%;
}
.kontent{
    display: flex;
    
}
.description{
    /* color: #ffffff;
    position: relative;
    bottom: 180px;
    left: 380px;
    width: 50%;
    font-family: 'SofiaPro-Light'; */
    color: #ffffff;
    position: relative;
    bottom: 180px;
    /* left: 380px; */
    width: 50%;
    font-family: 'Montserrat-Light';
    width: 1154px;
    margin: 0 auto;
    padding-left: 90px;
    padding-right: 15px;
    
}
.description p{
  color: #ffffffd4;
  font-size: 20px;
  margin-bottom: 13px;
}
.description span{
  font-size: 13px;
  opacity: 0.3;
  text-transform:uppercase;
}
.paragraphs{
  margin-right: 80px;
    height: 100%;
}
.butona{
    display: flex;
    height: 62px;
    position: relative;
    right: 70px;
}
.footeri{
    width: 100%;
}
.supercont{
    
        width: 1154px;
        margin: 0 auto;
        padding-left: 90px;
        padding-right: 15px;
    
}
.komp{
  color: white;
    width: 100%;
    padding-right: 110px;
    padding-top: 20px;
    position: sticky;
    top: 0;
}
@media only screen  and (max-width:768px ){
    .supercont{
    
        width: 100%;
        margin: 0 auto;
         padding-left: 0px;
         padding-right: 0px; 
         border-top: 1px solid rgb(241 239 239 / 24%);

}
    /* .container {
        width: 100%;
        margin: 0 auto;
        padding-left: 2px;
        padding-right: 15px;
    } */
  .kontent{
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .butona{
    display: flex;
    position: relative;
    right: 0;
    height: 58px;
}
  .container{
    color: antiquewhite;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-family: 'Montserrat-Light';
    line-height: 20px;
  }
  .description{
    /* color: #ffffff;
    position: relative;
    bottom: 0px;
    left: 50px;
    font-family: 'SofiaPro-Light';
    padding-bottom: 20px; */
    color: #ffffff;
    position: relative;
    bottom: 0px;
    /* left: 50px; */
    font-family: 'Montserrat-Light';
    padding-bottom: 20px;
    width: 70%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 40px;
    margin-right: 0;
    padding-top: 12px;
  }
  .description p{
    font-size: 17px;
    color: #ffffffd4;
    margin-bottom: 6px;
  }
  .description span {
    font-size: 13px;
    
  }
  .paragraphs{
    padding-right: 40px;
    padding-left: 40px;
    margin-right: 0;
    padding-bottom: 11px;
  }
  .footeri{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .post {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    height: 100%;
    padding-top: 30.9%;
    
  }
  .content{
    /* color: #ffffffd4;
    width: 70%;
    height: 100%;
    font-size: 15px;
    font-family: 'SofiaPro-Light';
    line-height: 20px; */
    /* padding-right: 30px; */
    /* padding-left: 50px;
    padding-bottom: 24px; */
    color: #ffffffd4;
    width: 100%;
    height: 100%;
    font-size: 13px;
    font-family: 'Montserrat-Light';
    line-height: 20px;
    margin: 0;
}
.content img {
    width: 100%;
    object-fit: contain;
    padding: 0;
    margin: 0;
    height: auto;
}
.content figure {
    margin: 0;
}
.komponente{
    color: white;
    width: 100%;
    position: inherit;
    left: 60px;
    display: flex;
    flex-direction: column;
    /* padding: 80px; */
    /* padding: 50px 90px 50px 78px; */
    background-color: #101010;
    margin: 0;
    padding-right: 0;
    padding-bottom: 22px;
    padding-top: 35px;
}
 body{
     max-width: 100%;
 }

 .thumbnail {
    width: 100%;
    height: 180px;
    overflow: hidden;
    object-fit: cover;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1268px) {
  .supercont{
    width: 100%;
    margin: 0 auto;
    padding-left: 90px;
    padding-right: 15px;
}
.description{
  /* color: #ffffff;
  position: relative;
  bottom: 180px;
  left: 380px;
  width: 50%;
  font-family: 'SofiaPro-Light'; */
  color: #ffffff;
  position: relative;
  bottom: 140px;
  /* left: 380px; */
  width: 50%;
  font-family: 'Montserrat-Light';
  width: 1154px;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 15px;
  
}
/* .komponente{
  width: 100%;
  position: relative;
  right: 115px;
}
.paragraphs{
  margin: 0;
  padding-right: 200px;
}*/
.supercont{
  width: auto;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 15px;
}
} 


.teamSingle{
	display: flex;
	align-items: center;
}

.teamSingle img{
	object-fit: cover;
	border-radius: 100%;
	margin-right: 15px;
	width: 75px;
	height: 75px;
}

.teamSingle h6{
	font-family: "SofiaPro-Regular";
	font-weight: 400;
	font-size: 17px;
	color: #000000;
	margin: 0;
}

.teamSingle p{
	font-family: "SofiaPro-Regular";
	font-size: 14px;
	color: #000000;
	opacity: 0.2;
	text-transform: uppercase;
	margin-bottom: 0;
    margin-top: 3px;
}

@media screen and (max-width: 767px) {
	.teamSingle img{
		width: 50px;
		height: 50px;
	}
	.teamSingle h6{
		font-size: 14px;
	}
	.teamSingle p{
		font-size: 12px;
	}
}
.slick-slide > div {
    padding: 0px;
    margin: 0px;
	display: flex;
    align-items: center;
}

.slick-slide > div img{
	object-fit: cover;
}

.slick-slider,
.slick-track,
.slick-slide > div,
.slick-slider .slick-list{
	height: 100%;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #000;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/background.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 50;
}
.horizontalLine1 {
  display: none;
}
.horizontalLineMobile {
  display: none;
}
.chooseVersionContainer {
  position: absolute;
  top: calc(50vh - 23px);
  left: 9%;
  z-index: 500;
  letter-spacing: 5px;
  height: 50px;
  width: 20vw;
  display: flex;
  align-items: center;
  background-color: #000;
}

.chooseVersionBackground {
  width: 110%;
  height: 90%;
}
.sideTxt {
  color: #fff;
  font-family: "SofiaPro-Black";
  font-size: 1.5vw;
  line-height: 3px;
  position: fixed;
  margin-left: 0.5%;
  margin-top: 1.34%;
}
.horizontalLine {
  border: 0.5px solid rgba(255, 255, 255, 0.08);
  width: calc(100% + 1000px);
  position: relative;
  left: -46%;
  z-index: 5000;
}

.topBackgroundStyle,
.bottomBackgroundStyle {
  width: 55vw;
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ease;
  position: relative;
  cursor: pointer;
}
@keyframes fadeInOutAnimation1 {
  from {
    left: 0px;
  }

  to {
    left: 200px;
  }
}
.textTop {
  z-index: 200;
  color: #fff;
  font-family: "SofiaPro-Black";
  font-size: 85px;
  margin-top: 150px;
  animation: zoom-out-zoom-in 0.5s ease;
}
@keyframes zoom-out-zoom-in {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.textBottom {
  z-index: 400;
  color: #fff;
  font-family: "SofiaPro-Black";
  font-size: 85px;
  margin-bottom: 150px;
  text-align: center;
  animation: zoom-out-zoom-in 0.5s ease;
}
@media only screen and (max-width: 850px) {
  .container {
    background-image: none;
    padding-top: 25%;
    overflow: hidden;
    justify-content: center;
    position: relative;
  }
  .photoContainer {
    margin: 10%;
  }
  .topBackgroundStyleMobile {
    bottom: 0;
    position: relative;
    top: 4px;
  }
  .bottomBackgroundStyleMobile {
    bottom: 0px;
    position: relative;
    bottom: 1px;
  }
  .chooseVersionContainer {
    top: 15%;
    left: 0;
    width: auto;
    background-image: none;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    border-top: 1px solid rgb(39, 39, 39);
    border-bottom: 1px solid rgb(39, 39, 39);
    height: 3.5%;
  }
  .chooseVersionBackground {
    display: none;
  }
  .sideTxt {
    opacity: 0.2 !important;
    font-size: 14px;
    line-height: 2px;
    position: static;
    margin-top: 10px;
  }
  .textTop {
    font-size: 14.5px;
    font-family: "SofiaPro-Black";
    transform: rotate(-90deg);
    text-align: center;
    color: #58c2d7;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 23%;
    animation: none;
  }
  .textBottom {
    font-size: 15px;
    font-family: "SofiaPro-Black";
    transform: rotate(-90deg);
    text-align: center;
    color: #b940aa;
    margin: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 25%;
    animation: none;
  }
}

@media only screen and (max-width: 370px) and (-webkit-min-device-pixel-ratio: 2) {
  .container {
    background-image: none;
    top: 0%;
    justify-content: center;
    overflow-x: hidden;
    position: absolute;
  }

  .topBackgroundStyleMobile {
    bottom: 0;
    position: relative;
    top: 0.7%;
  }

  .bottomBackgroundStyleMobile {
    bottom: 0px;
    position: relative;
  }

  .chooseVersionContainer {
    top: 14%;
    left: 0;
    width: auto;
    background-image: none;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040;
    height: 4%;
  }

  .chooseVersionBackground {
    display: none;
  }
  .sideTxt {
    opacity: 0.2 !important;
    font-size: 14px;
    text-align: center;
    line-height: 2px;
    position: static;
    margin-top: 10px;
  }

  .textTop {
    font-size: 17px;
    transform: rotate(-90deg);
    text-align: center;
    color: #58c2d7;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 22%;
    animation: none;
  }

  .textBottom {
    font-size: 17px;
    transform: rotate(-90deg);
    text-align: center;
    color: #b940aa;
    margin: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 20%;
    animation: none;
  }
}

@media only screen and (max-width: 400px) and (min-width: 370px) {
}

@media only screen and(max-width:360px) and (min-width: 350px) {
}
@media only screen and (max-width: 350px) and (min-width: 330px) {
  .container {
    background-image: none;
    top: 4%;
    justify-content: center;
    position: absolute;
  }

  .topBackgroundStyleMobile {
    bottom: 0;
    position: relative;
    top: 6px;
  }

  .bottomBackgroundStyleMobile {
    bottom: 0;
    position: relative;
  }

  .chooseVersionContainer {
    top: 16%;
    left: 0;
    width: auto;
    background-image: none;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040;
    height: 4%;
  }

  .chooseVersionBackground {
    display: none;
  }
  .sideTxt {
    opacity: 0.2 !important;
    font-size: 14px;
    text-align: center;
    line-height: 2px;
    position: static;
    margin-top: 10px;
  }

  .textTop {
    font-size: 17px;
    transform: rotate(-90deg);
    text-align: center;
    color: #58c2d7;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 22%;
    animation: none;
  }

  .textBottom {
    font-size: 17px;
    transform: rotate(-90deg);
    text-align: center;
    color: #b940aa;
    margin: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 20%;
    animation: none;
  }
}

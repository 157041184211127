.socialContainer {
  position: absolute;
  bottom: 6%;
  height: 3.5%;
  background-color: transparent;
  display: flex;
  z-index: 7;
  width: calc(100% - 94vw);
  margin: 0 auto;
  left: 0;
  margin-right:1%;
}

.darkSocialContainer {
  position: absolute;
  bottom: 6%;
  height: 30px;
  background-color: transparent;
  display: flex;
  z-index: 7;
  width: calc(100% - 140px);
  margin: 0 auto;
  left: 0;
  right: 0;
  padding-bottom: 25px;
}

.lightSocialContainer{
  position: absolute;
  bottom: 40px;
  height: 30px;
  z-index: 9999;
  left: 65px;
}

.lightSocialContainer img{
  width: 30px;
  height: 30px;
}

.lightSocialContainer a{
  display: inline-block;
  margin-right: 5px;
}

.socialContainer a,
.darkSocialContainer a {
  padding-right: 5px;
}

.socialIcon {
  width: 30px;
  height: 30px;
}

.darkSocialIcon {
  width: 35px;
  height: 35px;
}

@media only screen and (max-width: 1024px) {
  .lightSocialContainer{
    bottom: 15px;
    left: 30px;
  }
}

@media only screen and (max-width: 800px) {

  .socialContainer {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
    width:80%;
    margin-bottom: -3%;
  }

  .darkSocialContainer{
    margin: 0 auto;
    margin-bottom: -10%;
    display: flex;
    justify-content: center;
  }

  .socialIcon {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 370px){

  .socialContainer {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
    width:80%;
    margin-bottom: 0% !important;
  }

  .darkSocialContainer{
    margin: 0 auto;
    margin-bottom: -12%;
    display: flex;
    justify-content: center;
  }

  .socialIcon {
    width: 30px;
    height: 30px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 800px) ,screen and (min-height: 800px) and (max-height: 850px) {
  .socialContainer {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
    width:80%;
    margin-bottom: 1%;
  }
  .darkSocialContainer{
    width: 100vw;
    position:relative;
    margin: 7%;
    padding-top: 0px;
    display: flex;
    justify-content: center;
  }
  .socialIcon {
    width: 30px;
    height: 30px;
    margin-top: 30px;
  }
}
@media only screen 
and (max-width: 350px) and (min-width: 270px),screen and (min-height: 200px){
  .darkSocialContainer{
    margin-top: 0%;
  }
}
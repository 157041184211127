.container p{
	color: #fff;
}
.partners{
	width: auto;
    height: 100%;
    background-color: #fff;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
}
.partnerContainer{
	width: 100%;
    height: 100%;
  }
  .partnerContainer img {
	  width: 100%;
	  height: 75px;
	  object-fit: contain;
  }

  @media only screen  and (max-width:768px){
      .partners{
          padding: 0;
      }
    
  }
.masonery{
    display: grid;
    grid-gap: 13px;
    padding: 13px;
    grid-template-rows: auto auto ;
    grid-auto-flow: column;
    overflow-x: auto;
    margin-top: 10%;
}
/* .masonery{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    max-height: 80vh;
    overflow-x: scroll;   
    max-width: 100%;
} */

.square{
    max-width: 312px;
    max-height: 307px;
    object-fit: contain;
}
.rectangle{
    max-width: 312px;
    max-height: 410px;
    object-fit: contain;
}
.smallrectangle{
    max-width: 312px;
    max-height: 188px;
    object-fit: contain;
}
.tallrectangle{
    max-width: 312px;
    max-height: 529px;
    object-fit: contain;
}


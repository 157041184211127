


    
      .bright img {
        transition: 0.1s ;
        opacity: 0.7;

      } 

      .bright img {
 
        transition: .5s;
      }
      .imgsize{
        max-height : 500px; 
         max-width: 320px;
    }
      
      /* .bright img::before {
        content: '';
        opacity: 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #ffee10;
        transition: .5s;
        transform: scale(.9);
        z-index: -1;
      }
      
      .bright img:hover::before {
        transform: scale(1.1);
        box-shadow: 0 0 50px #ffee10;
      }
      
      .bright img:hover {
        opacity: 1;
        color: #ffee10;
        box-shadow: 0 0 50px #ffee10;
        text-shadow: 0 0 50px #ffee10;
      } */
      .bright img:hover{
        opacity: 1;
      }
      .bright:hover::after {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(45deg, #8E3186, #58C2D7, #8E3186,#58C2D7, #8E3186, #58C2D7, 
        #8E3186, #58C2D7,#8E3186, #58C2D7);
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: -1;
        animation: steam 20s linear infinite;
      }
      
      @keyframes steam {
        0% {
          background-position: 0 0;
        }
        50% {
          background-position: 400% 0;
        }
        100% {
          background-position: 0 0;
        }
      }
      
      .bright img:after {
        filter: blur(50px);
      }
      @media screen and (max-width: 768px) {
        .imgsize{
            max-height: 150px;
            max-width: 150px;
        }
        .bright img {
         
            opacity: 1;
    
          } 
      }
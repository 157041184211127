.slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: #fff;
    padding: 30px;
	margin: 20px
}

.slick-slider,
.projectsContainerslides{
	width: 100%;
}

.projectsContainerslides .slick-slide > div{
    padding: 0px;
	margin: 0px;
	height: 100%;
    display: flex;
	margin: 0 3px;
}
.projectsContainerslides .slick-slide > div img{
  width: 200px;
  height: 200px;
}

.projectsPortofolio{
    width: 100%;
}

.slick-slider,
.projectsPortofolio{
	width: 100%;
}
.projectsPortofolio .slick-slide > div{
    background: transparent;
    padding: 0;
    margin: 0;
	width: 100%;
	display: flex;
	height: auto;
}

.projectsPortofolio .square {
    background: transparent;
    padding: 0;
    margin: 0;
	display: flex;
	height: auto;
	width: 300px !important;
	height: 300px !important;
	object-fit: cover;
}


.projectsPortofolio .rectangle {
    background: transparent;
    padding: 0;
    margin: 0;
	display: flex;
	height: auto;
	width: 300px !important;
	height: 400px !important;
	object-fit: cover;
}

.projectsPortofolio .slick-initialized .slick-slide{
	width: auto !important;
    margin-right: 5px;
	display: flex;
}
.projectsPortofolio .slick-track{
	display: flex;
	margin: 0;
	height: auto;
}
.projectsPortofolio .Gallery_customrow__2SvjW{
	height: 741px;
	width: 100%;
}

.partnersContainerMobile .slick-slide{
	width: 100%;
	max-height: 50px;
	object-fit: contain;
}
.partnersContainerMobile img{
	width: 100%;
	max-height: 250px;
	max-width: 250px;
	object-fit: contain;
	/* padding: 20px 20px; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.partnersContainerMobile .slick-slide > div{
    padding: 20px;
	margin: 0px 10px;
}

.partnersSeriousContainerMobile .slick-slide{
	width: 100%;
	max-height: 50px;
	object-fit: contain;
}
.partnersSeriousContainerMobile img{
	width: 100%;
	max-height: 150px;
	max-width: 150px;
	object-fit: contain;
	/* padding: 20px 20px; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.partnersSeriousContainerDesktop{
	padding: 30px 100px !important;
}
.partnersSeriousContainerDesktop .slick-slide > div{
    padding: 5px;
	box-shadow: none;
	/* margin: 0px 10px; */
}
.partnersSeriousContainerMobile .slick-slide > div img{
	background-color: #fff;
}

.partnersSeriousContainerMobile  .slick-dots {
	margin-top: 0;
	color: black;
}
.partnersContainerMobile  .slick-dots {
	margin-top: 6%;
}

.projectsContainerslides .slick-slide > div img{
	width: 200px;
	height: 200px;
}
.icons_mobile .slick-slide > div img{
	object-fit: contain;
	max-width:100px;
	height: 70px;
}
.icons_mobile .slick-slider{
	position: relative;
	left: -14px;
}
.icons_mobile {
	margin-top: 30px;
	margin-bottom: 30px;
}

.ServiceContainer .slick-slide > div{
	background: transparent;
	padding: 15px;
	box-shadow: none;
}
.ServiceContainer .slick-vertical .slick-slide{
    color: aliceblue;
	cursor: pointer;
}
.ServiceContainer .slick-list{
	padding: 0 !important;
}
.ServiceContainer{
	width: 450px;
}
.ServiceContainer .slick-center.slick-current{
	opacity: 1;
	width: 440px !important;
	margin-left: -15px;
}
.ServiceContainer .slick-slide{
	opacity: 0.5;
	width: 400px  !important;
}

.ServiceContainer.reveal {
	position: relative;
	opacity: 0;
  }
  
  .ServiceContainer.reveal.active {
	opacity: 1;
  }
  .ServiceContainer.active.fade-left {
	animation: fade-left 1s ease-in;
  }
  @keyframes fade-left {
	0% {
	  transform: translateX(-100px);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
  }
@media screen and (max-width: 1024px) {
	.slick-slide > div {
		padding: 20px;
		margin: 15px 30px;
	}

	.slick-list {
		padding:0 20% 0 0 !important;
	}
	.Portfolio_partners__xgVFf .slick-initialized .slick-slide{
        display: flex;
        justify-content: center;
      }
      .Portfolio_partners__xgVFf  .slick-slide > div{
          box-shadow: none;
      }
}

.miniContainer{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    flex-wrap: wrap;
    padding-left: 380px;
    padding-right: 380px;
 
}

.container{
    margin-top: 200px !important;
}
@media only screen and (max-width:800px) {
    .miniContainer{
        padding-left: 50px;
        padding-right: 50px;
    }
}
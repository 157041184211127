.contactContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: url("../../assets/contact/border.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffffff !important;
}

/* .backgroundImg{
	position: absolute;
    z-index: 9;
    height: 100%;
    width: 100%;
    min-height: 100vh;
} */

/*
.formContainerContent{
	padding: 67px 80px 0 80px;
}
*/

/* .formContainerContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
} */

.sliderContainer {
  background-color: #fff;
  position: relative;
}

.sliderContainer {
  width: 40.7%;
  /* height: 100vh; */
  height: 100%;
  padding: 182px 0 104px 0 !important;
}

.formContainer {
  position: relative;
  z-index: 0;
  width: 40.3%;
  background-color: black;
  /* background-color: red; */
}

.verticalLine {
  border: 2px solid #58c2d7;
  max-height: 60px;
  margin-top: 10px;
  background-color: #58c2d7;
}
.mapContainer {
  width: 19%;
}

h5,
h6,
p {
  color: #fff;
}

h4 {
  color: #58c2d7;
  font-family: "Montserrat-Light" !important;
  font-size: 22px;
  line-height: 24px;
  font-weight: 100;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 17px;
}

.infoContact {
  display: flex;
  gap: 20px;
}

.careers {
  padding: 90px 83px 10px 83px;
  border-top: 1px solid #343434;
}

.careers h5 {
  font-size: 14px;
  font-family: "SofiaPro-Semibold";
  font-weight: 100;
  line-height: 14px;
  margin: 0 0 10px 0;
}

.careers h6 {
  font-size: 20px;
  font-family: "Montserrat-Light";
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 15px 0;
}

.careers p {
  font-size: 11px;
  font-family: "Montserrat-Light";
  font-weight: 100;
  line-height: 1.2;
  width: 70%;
  margin: 0 0 15px 0;
  opacity: 0.89;
}

.careers .contactButton {
  font-size: 13px;
  font-family: "SofiaPro-Medium";
  font-weight: 100;
  line-height: 16px;
  background-color: #58c2d7;
  padding: 12px 22px;
  color: #fff;
  border-radius: 2px;
  border: none;
}

input {
  background-color: transparent;
  border: 1px solid rgba(225, 225, 225, 0.2);
  padding: 16px;
  line-height: 1.2;
  color: #ffffff91;
  outline: none;
  border-radius: 2px;
}

textarea {
  background-color: transparent;
  border: 2px solid #ffffff2e;
  padding: 18px 16px;
  color: #ffffff91;
  border-radius: 2px;
  outline: none;
}

textarea::placeholder,
input::placeholder {
  font-size: 16px;
  font-family: "SofiaPro-Light";
  font-weight: 400;
  line-height: 1.2;
  opacity: 0.5;
  color: #ffffff;
}

.contactFormContent form p {
  font-size: 13px;
  font-family: "SofiaPro-Regular";
  line-height: 24px;
  margin-bottom: 3px;
}

.contactFormContent form .formGroup p {
  margin-top: 18px;
}

.formGroup {
  display: flex;
  justify-content: space-between;
}

.formGroup > div {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.formGroupSubmit {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.formGroupSubmit .formGroupMsg {
  width: 83%;
  display: flex;
  flex-direction: column;
}

.formGroupSubmit .submitContainer {
  width: 17%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.submitContainer img {
  position: absolute;
  /* bottom: -79px; */
  display: inline-block;
  bottom: 40px;
  cursor: pointer;
  z-index: 0;
}

.submitContainer input {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 42px 26px;
  width: 100%;
  color: transparent;
  cursor: pointer;
  z-index: 1;
}

.contactFormContent {
  padding: 47px 83px 60px;
}

.formGreeting {
  padding: 0px 83px 35px 83px;
}

.greeatingImg {
  margin-bottom: 93px;
  margin-top: 67px;
}

.title {
  font-size: 14px;
  font-family: "SofiaPro-Semibold";
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 0;
}

.text {
  font-size: 16px;
  font-family: "Montserrat-Light";
  line-height: 24px;
  margin: 2px 0px;
  font-weight: 100;
}

.infoContactLocation {
  padding-left: 21px;
  width: 47%;
}

.infoContactLocation .title {
  color: #58c2d7;
  font-weight: 100;
  text-transform: uppercase;
}

.socialButtonsMobile,
.formGreetingMobile,
.greeatingImgMobile {
  display: none;
}

.map {
  width: 100%;
  height: 100%;
}

.mapContainer {
  filter: grayscale(1) !important;
}
.mapContainer:hover {
  filter: none !important;
}

.socialButtons {
  z-index: 1;
}
.speechbubble {
  position: relative;
  background: #000000;
  background-image: linear-gradient(to bottom, #18c3da, #e806f0);
  border-radius: 2.4em;
  width: max-content;
  height: 100px;
  top: -201.128px;
  right: 57px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  filter: grayscale(0) !important;
}
.pin {
  filter: none !important;
}

.speechbubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 31px solid transparent;
  border-top-color: #e806f0;
  border-bottom: 0;
  border-left: 0;
  margin-left: -15.5px;
  margin-bottom: -31px;
  /* filter: none !important; */
  filter: grayscale(0) !important;
}
.logo1up {
  height: 100%;
  width: 25%;
  object-fit: contain;
}
.desc1up {
  color: #ffffff;
  font-size: 20px;
  padding-right: 20px;
  margin: 0;
}
.getDirection {
  height: 20px;
  filter: invert(1) brightness(100%) sepia(100%) saturate(100%);
}
.direction {
  display: flex;
  align-items: center;
  position: relative;
  right: 100px;
  /* margin-bottom: 62px; */
  margin-top: -60px;
  margin-right: -100px;
  margin-left: 50px;
}
@media only screen and (max-width: 1900px) {
  .sliderContainer {
    min-height: 100vh;
  }
}
@media only screen and (min-width: 1024px) {
  .msgCelContact {
    width: 70%;
  }

  .msgCelContact p {
    font-size: 16px;
    font-family: "Montserrat-Light";
    font-weight: 100;
    line-height: 24px;
  }

  .msgCelContact p img {
    margin-right: 7px;
  }

  .careers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .careersText {
    width: 74%;
    margin-top: -2%;
  }
}

@media only screen and (max-width: 850px) {
  .contactContainer {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: auto;
    flex-direction: column;
    background-image: none;
    background-color: #000000 !important;
  }
  .mapContainer {
    filter: grayscale(0) !important;
  }
  .mapContainer:hover {
    filter: none !important;
  }

  .sliderContainer {
    background-color: transparent;
    padding: 0 !important;
    margin-top: 129px;
  }

  .sliderContainer,
  .formContainer,
  .mapContainer {
    width: 100%;
  }

  .mapContainer .map {
    height: 300px;
    padding-top: 35px;
  }

  .formContainerContent {
    display: flex;
    flex-direction: column;
    margin-top: -148px;
  }

  .greeatingImgMobile {
    display: block;
    position: relative;
    top: -25px;
  }

  .greeatingImg {
    display: none;
  }

  .contactFormContent {
    order: 1;
    margin-top: -70px;
  }

  .careers {
    order: 2;
    padding: 50px 30px 0;
  }

  .formGreeting {
    order: 3;
  }

  .formGroupSubmit,
  .formGroup {
    flex-direction: column;
  }

  .formGroup > div {
    width: 100%;
  }

  .formGroupSubmit .submitContainer,
  .formGroupSubmit .formGroupMsg {
    width: 100%;
    height: 100px;
    background-color: red;
  }

  .formGreeting,
  .backgroundImg {
    display: none;
  }

  .contactFormContent form .formGroup p {
    margin-top: 16px;
  }

  .greeatingImg {
    margin-bottom: 30px;
  }

  .formGreeting {
    padding: 30px;
  }

  .careers p {
    width: 90%;
  }

  .careers .contactButton {
    font-size: 13px;
    font-family: "SofiaPro-Medium";
    font-weight: 100;
    line-height: 1.2;
    background-color: #58c2d7;
    padding: 11px 21px;
    color: #fff;
    border-radius: 4px;
    border: none;
  }

  .contactFormContent {
    padding: 0px 30px 0px 30px;
    border: none;
  }

  .careers {
    padding: 50px 30px 0 !important;
    border: none;
  }

  .text img {
    padding-right: 4px;
  }

  .text {
    font-size: 13px;
    font-family: "Montserrat-Light";
    line-height: 20px;
    margin: 2px 0px;
  }

  .infoContactCel,
  .infoContactDay {
    width: 100%;
  }

  .infoContactLocation {
    border-left: none;
    padding: 18px 30px;
    width: 70%;
    background-color: #58c2d7;
    position: relative;
    top: -64px;
  }

  .contactFormContent h4 {
    margin-bottom: 0;
  }

  input {
    padding: 15px;
    line-height: 1.2;
  }

  textarea {
    padding: 15px 10px;
  }

  .submitContainer input {
    padding: 15px;
    margin-top: 20px;
    line-height: 1.2;
    background-color: #58c2d7;
    border-color: #58c2d7;
    color: #fff;
    font-size: 14px;
    justify-content: flex-start;
  }

  .submitContainer img {
    bottom: 17px;
    right: 20px;
    width: 5%;
    z-index: 1;
  }

  .socialButtonsMobile,
  .formGreetingMobile {
    display: block;
  }

  .infoContactLocation .title {
    color: #ffffff;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0;
  }

  .formGreetingMobile h4 {
    padding: 30px 30px 0px 30px !important;
    margin-bottom: 10px;
    margin-top: -64px;
  }

  .infoContact {
    flex-direction: column;
    padding: 0px 30px 0px 30px;
  }

  .socialIcons img {
    /* padding: 0px 0px 0px 25px; */
    font-size: 20px;
    align-self: center;
    margin: 0 auto;
  }
  .socialIcons {
    text-align: center;
    float: left;
    width: 27px;
    height: 27px;
    border: 1px solid #9b9898;
    border-radius: 100%;
    margin-right: 7px; /*space between*/
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .map {
    padding-top: 50px !important;
  }
  .infoContact {
    gap: 0;
  }
}

@media only screen and (max-width: 760px) and (max-height: 650px) {
  .sliderContainer {
    margin-top: 144px;
  }
  .formContainer {
    margin-top: -262px;
  }
  .socialButtons {
    display: none;
    pad: 20px 0 20px 26px;
  }

  .mapContainer .map {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 855px) {
  .mapContainer {
    display: none !important;
  }
  .sliderContainer,
  .formContainer {
    width: 50% !important;
  }
  .sliderContainer {
    height: 98vh;
  }
}

@media only screen and (max-height: 490px) and (max-width: 350px) {
  .sliderContainer {
    margin-top: 45px !important;
  }
  .socialButtons {
    display: none;
  }
  .formContainer {
    margin-top: -45px;
  }
  .mapContainer .map {
    padding-top: 70px;
  }
}
@media only screen and (max-height: 930px) and (min-height: 800px) and (max-width: 420px) {
  .sliderContainer {
    margin-top: 120px !important;
  }
  .socialButtons {
    display: none;
  }
  .formContainer {
    margin-top: -221px;
  }
  .mapContainer .map {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 360px) and (min-width: 355px) and (max-height: 670px) {
  .contactFormContent {
    margin-top: 141px;
  }
  .sliderContainer {
    margin-top: 106px;
  }
  .contactContainer {
    background-color: #000000 !important;
  }
}

@media only screen and (max-width: 360px) and (min-width: 355px) and (min-height: 680px) and (max-height: 740px) {
  .contactFormContent {
    margin-top: -180px;
  }
  .sliderContainer {
    margin-top: 129px;
  }
}

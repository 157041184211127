.container{
    background-color: black;
    width: 78.2%;
    margin-top: 13vh;
    display: flex;
    margin-left: auto;
    height: 100%;
    overflow: hidden;
}
.main{
    width: 50%;
}
.side{
    width: 50%;
    overflow: hidden;
    margin-top: 4%;
}
.logo span{
    color: rgb(183, 207, 200);
    width: 41px;
    height: 41px;
}
.logo img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.companyName{
    line-height: 7px;
}
.comapnyTitle{
    font-size: 20px;
    margin-left: 2px;
}
.comapnyDate{
    font-size: 14px;
    opacity: 0.3;
    margin-left: 2px;
}
.companyInfo{
    margin-top: 128px;
    margin-left: 2px;
}
.title{
    font-size: 28px !important;
    margin-left: 2px;
    margin-bottom: 0;
}
.desc{
    font-size: 15px;
    margin-left: 2px;
}
.icons{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.organize{
    display: grid;
    /* display: flex;
    flex-direction: row; */
    font-size: 14px;
    /* flex-wrap: wrap; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.styling{
    display: grid;
    margin-right: 30px !important;
    color: whitesmoke;
    grid-template-columns: 1fr 1fr ;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ikonatSpecifikim{
    width: 40px !important;
    height: 40px !important;
}

.styling img{
    margin-right: 10px;
}
.technologyIcons{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    width: 35%;
}

.textEnd{
    margin-top: 120px;
    background-color:rgb(26, 26, 26);
    font-size: 15px;
    padding: 70px 44px;

}
.textDesc{
    font-size: 15px;
    margin: 0 !important;
    padding: 0!important;
    /* margin-left: -42px !important; */
}
.mobilePhoto{
    display: flex;
    /* margin-top: 8%; */
    /* position: relative; */
    justify-content: center;
    align-items: center;
    object-fit: contain;
    /* margin-left: 10%; */
    /* right: 8.2%; */
    /* top: -3%; */
}
.circle{
    display: flex;
    justify-content:  flex-end;
    align-items: center;
    height: 190px;
    margin-top: 9%;
}
.outerCircle{
    width: 375px;
    height: 375px;
    border-radius: 50%;
    border: #272727 solid 1px;
}
.innerCircle{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: #ece7e7 solid 1px;
    position: relative;
    left: 100px;
    top: 90px;
    cursor: pointer;
}
.first{
    opacity: 1;
    font-size: 40px;
    color: #ece7e7;
    text-align: center;
    align-items: center;
    position: relative;
    left: 35px;
    top: 25px;
    line-height: 15px;
 }

 .testing{
    opacity: 0;
    font-size: 40px;
    color: #ece7e7;
    text-align: center;
    align-items: center;
    position: relative;
    left: 35px;
    top: 25px;
 }
 .smallCircle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ece7e7;
    position: relative;
    top: 70px;
    right: 20px;
    transition: 0.2s;
}
.innerCircle:hover{
    background:linear-gradient(#902D84,#8E3186,#88408E,#7F579B,#7379AE,#63A3C5,#58C2D7,#58C2D7);
    border: #0f0f0f solid 1px;
}  

.innerCircle:hover .first{
    opacity:0;
    display: none;
}
.ikonat_tek{
    width: 40px;
    height: 40px;
}

.innerCircle:hover .testing{
    opacity:1;
    transition: 0.7s ease-in-out;
    line-height: 40px;
    top: 15px;
}
.innerCircle:hover .smallCircle{
    box-shadow:  0px 0px 0px 10px rgba(0, 0, 0, 1) inset ;
    background-clip: content-box;
    padding: 0.7px;
    cursor: pointer;
}  
@media screen and (max-width:900px) {
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .logo{
        display:none;
    }
    .comapnyDate{
        display: none;
    }
    .comapnyTitle{
        font-size: 20px;
        font-family: 'SofiaPro-Bold';
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }
    .main{
        width: 100%;
        margin-top: 8%;
    }
    .verticalLine_1{
        border: 1px solid #1a1a1a;
    }
    .verticalLine_2{
        border: 0.5px solid #1a1a1a;
    }
    .mobile_photo{
        display: flex;
        position: relative;
        margin-left: 10%;
    }
    .mobile_Photo{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:50px 77px;
    }
    .mobile_Photo img{
      width: 225px;
      height: 380px;
      object-fit: contain;
    }
    .mobilePhoto{
        display: none;
    }
    .companyInfo{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0px !important;
    }
    .organize{
        display: flex;
        flex-direction: column;
    }

    .icons{
        margin-top: 35px;
    }
    .side{
        width: 100%;
    }
    .miniContainer{
        padding-left: 40px;
        padding-right: 40px;
    }
    .title{
        color: #58C2D7;
        font-size: 18px !important;
        letter-spacing: 1px;
    }
    .technologyIcons{
        width: 100% !important;
        justify-content: space-evenly;
        margin-left: -30px;
        margin-top: 50px !important;
    }
    .technologyIcons{
        width: 30px;
        height: 33px;
        object-fit: contain;
    }
    .circle{
        display: none;
    }
    .innerCircle{
        top: 0;
        z-index: 9999999999999;
        left: 2px;
        /* transition: 0.7s ease-in-out; */
        animation: innerCircleAnimation  ease-in-out 4s infinite;
    }
    .thecircle{
        border-radius: 100%;
        border:20px solid black;
        z-index: 99999;
        position: absolute;
        margin-top: -4%;
        background-color: black;
        left: 76px;
    }
    .square{
        background-color: #272727;
        height: 200px;
        margin-top: 37%;
    }

    .smallCircle{
        animation: smallCircle 4s infinite;
    }

    .lastSide{
        display: flex;
        flex-direction: column;
    }

    .technologyIcons{
        display: none;
    }

    @keyframes innerCircleAnimation {
        from{
            background: #0f0f0f;
            border: #ece7e7 solid 1px;
        }

        to{
            background:linear-gradient(#902D84,#8E3186,#88408E,#7F579B,#7379AE,#63A3C5,#58C2D7,#58C2D7);
            border: #0f0f0f solid 1px;
            transition: 0.7s ease-in-out;
        }
    }

    
    @keyframes innerCircleAnimationFirst {
        from{
            opacity: 1;
            font-size: 40px;
            color: #ece7e7;
            text-align: center;
            align-items: center;
            position: relative;
            left: 35px;
            top: 25px;
            line-height: 15px;
        }

        to{
            opacity:0;
            display: none;
        }
    }
  
        
    @keyframes smallCircle {
        from{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ece7e7;
            position: relative;
            top: 70px;
            right: 20px;
            transition: 0.2s;
        }

        to{
            box-shadow:  0px 0px 0px 10px rgba(0, 0, 0, 1) inset ;
            background-clip: content-box;
            padding: 0.7px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:420px) and (min-width:400px){
   .lastSide{
    margin-top: -4%;
   } 
   .thecircle{
    margin-top: 2%;
    margin-left: 2.5%;
   }
}
@media screen and (max-width:360px) and (min-width:320px){
   .thecircle{
    left: 16.5%;
   }
}
.Desktopcontainer{
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    position: fixed;
    /* background-image: url('../../assets/background.png'); */
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.DesktopErrorPhoto{
   position: fixed;
   width: 100%;
   height: 100vh;
}
.Mobilecontainer{
    background: rgb(26,26,26);
    background: linear-gradient(180deg, rgba(26,26,26,1) 50%, rgba(15,15,15,1) 50%);
}

.MobileErrorPhoto{
    position: absolute;
    width: 100%;
}


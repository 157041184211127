.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.blackContainer {
  background-image: url("../../assets/about/bck.svg");
  
  background-color: #000;
  height: 70vh;
  display: flex;
  align-items: center;
}

.blackContainer p{
  color: rgb(255, 255, 255);
  font-family: "Montserrat-Light";
  font-size: 22px;
  line-height: 32px;
  font-weight: 100;
  margin: 0;
}



.firstContainer {
  width: 60%;
  margin: 0 auto;
}

.aboutPage h4{
  font-size: 22px;
  color: #fff;
  font-family: "SofiaPro-Bold" !important;
  line-height: 28px;
  max-width: 440px;
  width: 100%;
  margin-bottom: 40px;
  text-transform: none;
}
.biggerContainer{
  background-color: #fff;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1154px;
  display: flex;
  align-items: center;
}
.pContainer{
  background-color: #fff;
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
}

.aboutPage text{
  font-size: 22px;
  color: #fff;
  font-family: "SofiaPro-Light";
}

.whiteContainer .partners {
  display: grid;
  gap: 0;
  grid-template-columns: repeat(6, 1fr);
}


.partnerContainer{
  width: 193px;
  height: 130px;
  object-fit: contain;
}

.partnerContainer{
  width: 193px;
  height: 130px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImgDesktop{
  max-height: 130px;
  max-width: 190px;
  height: 100%;
  width: 100%;
}

.whiteContainerDesktop{
  border: 1px #f1ecec solid;
  padding: 30px 200px;
  background: white;
  cursor: pointer;
}

.whiteContainer{
  border-bottom: #E5E5E5 1px solid;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.secondContainer{
  padding: 20px;
}



.thirdContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.leftContainer {
  width: 44%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 250px;
}

.leftTextContainer {
  height: 100;
  display: flex;
  align-items: center; 
  justify-content: flex-end;
}

.leftBackgroundImage {
  object-fit: contain;
  width: 95%;
  margin-top: 90px;
  margin-left: 40%;
}

.rightContainer {
  width: 56%;
  background-color: #000;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  padding-left: 120px;
  padding-right: 120px;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.serviceIcon {
  width: 60px;
  object-fit: contain;
  height: 60px;
}

.serviceTextContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

.serviceleft{
  max-width: 300px;
}

.serviceright{
  max-width: 200px;
    margin-left: 60px;
    padding-left: 60px;
    border-left: 1px solid #ffffff36;
}

.serviceleft .serviceTextContainer{
  margin-left: 0px;
}

.serviceTextContainer:last-child {
  margin-bottom: 0px;
}

.serviceTextContainer span{
  color: #fff;
  font-size: 18px;
  font-family: "SofiaPro-Medium";
  font-weight: 400;
}

.serviceTextContainer p{
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat-Light";
  font-weight: 100;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 0;
}

.rightBackgroundImage {
  width: 40%;
  position: absolute;
  right: -22%;
  bottom: 0;
}

.content{
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}


.awardIcon_1 {
  width: 87px;
  height: 87px;
  object-fit: contain;
  margin-right: 25px;
  background-color: transparent;
}

.awardIcon_2 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 25px;
  background-color: transparent;
}

.conentText{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.conentGroup:first-child{
  margin-bottom: 20px;
}

.conentText p{
  font-family: "Montserrat-Light";
  opacity: 0.5; 
  color: #000;
  font-size: 14px;
  margin: 0;
}

.conentText .awardText {
  font-family: SofiaPro-SemiBold;
  opacity: 1; 
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
}

.awardBottomText {
  font-family: "Montserrat-Light";
  opacity: 0.5;
  color: #000;
  font-size: 14px;
  margin-top: 30px;
}

.awardLeftTextContainer {
  border-left-color: #54bacd;
  border-left-width: 3px;
  border-left-style: solid;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 25%;
}

.awardLeftTextContainer .subtitle{
  font-family: SofiaPro-Bold;
  font-size: 14px;
  color: #000000;
  margin-top: 0;
  padding-left: 30px;
  line-height: 1.2;
  opacity: 0.2;
}

.awardLeftTextContainer .title{
  font-family: SofiaPro-Medium;
  font-size: 28px;
  color: #000000;
  margin-top: 0;
  padding-left: 30px;
  line-height: 1.2;
  margin-bottom: 0;
}

.awardsContainer {
  display: flex;
  flex-direction: column;
}

.fourthContainer {
  background-color: #f5f5f5;
  display: flex;
  padding-top: 110px;
  padding-bottom: 110px;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
}

.bcklightGray{
  background-color: #f5f5f5;
  z-index: 2;
  position: relative;
}

.conentGroup{
  display: flex;
  align-items: center;
}
.conentGroup1{
  display: flex;
  margin-left: 9px;
}

.contactButton {
  background-color: #58c2d7;
  color: #fff;
  height: 40px;
  display: flex;
  width: 150px;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  font-family: SofiaPro-Medium;
  cursor: pointer;
}

.contactButtontIcon {
  width: 18px;
  object-fit: contain;
}

.fifthContainer {
  position: relative;
  z-index: 1;
  background-color: #000;
  padding-top: 100px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
}

.structureContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 90px;
  margin-bottom: 30px;
}

.contactTextContainer {
  width: 65%;
}

.contactTextContainer h4{
  font-family: SofiaPro-Semibold;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.contactTextContainer h5{
  font-family: "Montserrat-Light";
  font-weight: 100;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.contactTextContainer p{
  font-family: "Montserrat-Light";
  font-weight: 100;
  font-size: 11px;
  line-height: 13px;
  max-width: 455px;
  width: 100%;
}

.team{
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.team h2{
  color: #000000;
  margin: 0 auto;
  padding: 130px 0 20px 0;
  text-align: center;
}

.join{
  text-align: center;
  padding-bottom: 85px;
  padding-top: 20px;
}

.join a{
  background-color: #000000;
  padding: 8px 15px;
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  display: inline-block;
  font-family: SofiaPro-Medium;
  border-radius: 2px;
}

.join p{
  font-size: 12px;
  color: #000000;
  opacity: 0.3;
  font-family: "Montserrat-Light";
  margin: 0;
  line-height: 24px;
  margin-top: 4px;
}

.teamBgr{
  margin-left: -10%;
  position: absolute;
  top: -250px;
  z-index: 0;
}

.arrowIcon{
  height: 10px;
  margin-left: 15px;
}

.teamfullBgr{
  position: absolute;
  top: -5px;
  right: 5%;
  z-index: -1;
}


.leftTextContainer > p{
  font-family: SofiaPro-Medium;
  font-size: 28px;
  font-weight: 400;
  color: #000000;
  margin-top: 0;
  padding-left: 30px;
  line-height: 1.2;
  margin-bottom: 0;
  max-width: 54%;
  border-left-color: #54bacd;
  border-left-width: 3px;
  border-left-style: solid;
}

.desc {
  display: none;
}
@media screen and (max-width: 767px) {
  .blackContainer h4 {
    font-size: 18px;
  }

  .whiteContainerDesktop{
    display: none;
  }
  .blackContainer p {
    font-size: 16px;
    line-height: 30px;
  }

  .blackContainer .contentBlock {
    padding: 40px;
  }

  .blackContainer {
    background-image: none;
    background-color: #101010;
    height: 55vh;
    display: flex;
    align-items: flex-end;
  }

  .whiteContainer .partners {
    display: grid;
    grid-gap: 0;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);
    padding: 30px 15px;
  }

  .partnerContainer{
    height: 100%;
  }
  .pContainer{
    background-color: #fff;
    display: grid;
    gap: 0px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }

  .leftTextContainer {
    max-width: 300px;
  }
  .leftTextContainer > p{
    font-family: SofiaPro-Medium;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    margin-top: 0;
    padding-left: 15px;
    line-height: 22px;
    margin-bottom: 0;
    max-width: 65%;
    border-left-color: #54bacd;
    border-left-width: 3px;
    border-left-style: solid;
  }

  .serviceTextContainer {
    margin-bottom: 30px;
  }

  .serviceright {
    max-width: auto;
    margin-left: 0;
    padding-left: 0;
    border-left: none;
}
  
  .whiteContainer{
    height: auto;
    border-bottom: none;
  }

  .thirdContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .leftContainer {
    width: 100%;
    padding-top: 50px;
  }

  .leftBackgroundImage {
    width: 80%;
    margin-top: 50px;
    margin-left: 20%;
  }

  .rightContainer {
    width: 100%;
  }

  .serviceContainer {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .structureContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 90px;
    margin-bottom: 0px;
    flex-direction: column;
    padding: 45px 30px;
  }

  .serviceTextContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 0px;
  }

  .teamfullBgr,
  .teamBgr,
  .rightBackgroundImage {
    display: none;
  }

  .rightContainer {
    background-color: #101010;
  }

  .fourthContainer {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    flex-direction: column;
  }

  .awardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .awardsContainer > div {
    width: auto;
  }


  .awardLeftTextContainer .subtitle{
    font-size: 14px;
    color: #000000;
    margin-top: 0;
    padding-left: 10px;
    opacity: 0.2;
    margin-bottom: 5px;
  }

  .awardLeftTextContainer .title{
    font-size: 22px;
    color: #000000;
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 0;
    width: 70%;
  }

  .awardLeftTextContainer {
    margin-left: 0px;
    width: auto;
    margin-top: 50px;
  }
  .desc {
    color: rgba(0, 0, 0, .6);
    font-family:  "Montserrat-Light";
    font-size: 12px;
    display: inline;
    padding-top: 24px;
  }
  .awardText {
    font-size: 11px;
    padding-right: 8px;
  }

  .awardBottomText {
    font-size: 12px;
    padding-bottom: 30px;
    padding-left: 12px;
  }

  .team h2{
    padding: 50px 0 20px 0;
    text-align: center;
    font-size: 16px;
  }

  .join{
    padding-bottom: 50px;
  }

  .leftTextContainer {
    padding-left: 20px;
    width: 100%;
    margin-left: 0;
    justify-content: flex-start;
  }

  .team {
    background-color: #f5f5f5;
  }

  .serviceTextContainer span{
    font-size: 16px;
  }

  .contactTextContainer p{
    width: 150%;
  }

  .contactButton{
    margin-top: 15px;
    border: none;
    border-radius: 2px;
    padding: 12px 14px;
    display: flex;
  }
  .contactButtontIcon{
    width: 18.3px;
  }
  .biggerContainer{
    display: none;
  }
  .whiteContainerMobile{
    background-color: #fff;
  }
}
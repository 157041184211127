.slideshowContainerMobile .slick-slide > div{
	box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    background: none !important;
}
.slick-dots li button::before, 
.slideshowContainerMobile{
    background-color: transparent;
    color: #f4f2f7;
}
.slideshowContainerMobile{
    width: 100%;
    height: 197px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slideshowContainerMobile .slick-track {
    padding: 0 50px;
  }

  .slideshowContainerMobile .slick-slide.slick-active{
    margin: 0 -3px;
}

@media only screen and (max-width: 375px){
    .slideshowContainerMobile .slick-track {
        padding: 0 6px;
      }
    
      .slideshowContainerMobile .slick-slide.slick-active{
        margin: 0 30px;
    }
}



.text {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: aliceblue;
}
.blogh1{
    top: 220px;
    position: absolute;
}
.blogh1:hover {
    color: transparent;
}
.itemblog{
    display: flex;
    top: 0px;
    width: 100%;
    height: 33vh;
    /* UI Properties */
    opacity: 1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.overlay{
    width: 100%;
    height: 100%;
}
/* .inner{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
} */
.text:hover {
    /* other rules */
    color: transparent;
}
.inner{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(50px);
    transition: 0.5s ease;
    opacity: 0.7; 
    transform: scale(1.1); 
    -webkit-filter: blur(50px);
    backdrop-filter: brightness(50%) contrast(90%);
}
.inner:hover{
    opacity: 1;
    filter: blur(0px);
   
        /* transition: 0.5s ease; */
}
.ome{
    position: absolute;
    bottom: 10px;
    margin: 0;
    display: flex;
    font-size: 20px;
    color: #ffffffc7;
    flex-direction: column;
    font-family: 'Montserrat-Light';
    z-index: 1;
    bottom: 40px;
    padding-left: 49px;
    padding-right: 140px;
    text-align: initial
}
.datat{
    opacity: 0.5;
    font-size: 14px;
    padding-top: 12px;
    text-transform:uppercase;
}
.itemblog:hover .ome{
    display: none;
}
.covertext{
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
/* For mobile phones: */
.inner{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(0px);
    transition: 0.5s ease;
    opacity: 1;
    transform: scale(1);
    -webkit-filter: blur(0px);
    border: 0;
    background-position-x: 60%;
}
.datat{
    font-size: 13px;
    padding-top: 17px;
}
.inner:hover{
    /* opacity: 1; */
    filter: blur(0px);
   
        /* transition: 0.5s ease; */
}
.covertext{
    font-size: 17px;
    max-width: 80%;
}
.itemblog{
    display: flex;
    top: 0px;
    width: 100%;
    height: 150px;
    opacity: 1;
    cursor: pointer;
    position: relative;
    border: 0;
}
.ome{
    position: absolute;
    bottom: 30px;
    left: 33px;
    margin: 0;
    display: flex;
    font-size: 19px;
    color: white;
    flex-direction: column;
    z-index: 1;
    padding-left: 0;
}
.itemblog:hover .ome{
    display: flex;
}
  }
  @media only screen and (min-width: 769px) and (max-width: 1200px) {

.itemblog{
        display: flex;
        top: 0px;
        width: 100%;
        height: 33vh;
        /* UI Properties */
        opacity: 1;
        cursor: pointer;
        position: relative;
}
  }

  @media only screen and (min-width: 769px) and (max-width: 1268px) {
    .covertext{
        font-size: 15px;
    }

    .datat{
        font-size: 11px;
    }
    
  }
  
.NavBar {
  background: transparent;
  top: 0;
  width: 100%;
  z-index: 400;
  position: absolute;
}

.NavBarShow {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  position: fixed;
  background-color: white;
  background-image: url("../assets/menuBackground.png");
  background-position: "center";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 900;
}

.seriousLink {
  margin-right: 10 !important;
  padding-right: 5 !important;
  cursor: pointer !important;
}

.crazyLink {
  margin-left: 5 !important;
  margin-right: 20 !important;
}
.sideTextHeader,
a {
  text-decoration: none;
  color: rgb(245, 245, 245);
  text-align: right;
}
.sideTextHeader :nth-child(3) {
  width: 1px;
  height: 20px;
  background-color: #fff;
  margin-bottom: 10px;
  opacity: 0.2 !important;
}
.sideTextHeader {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 5rem !important;
  right: 1%;
  width: 88%;
  font-family: "SofiaPro-Light";
  font-size: 14px;
  border-right: 1px solid rgb(255, 255, 255, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.NavBarHide {
  display: none;
  z-index: 400;
}

.TopNav {
  width: 100%;
  flex-direction: row;
  height: 0px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-self: center;
}

.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 100px;
  height: 84px;
  object-fit: contain;
  padding: 30px 50px;
  margin-left: 5%;
  margin-top: 15%;
}

.menuLinks {
  display: flex;
  flex-direction: row;
  font-family: "SofiaPro-Regular";
  font-size: 52px;
  align-items: center;
  height: 45px;
  color: "#000";
  padding: 15px;
}

.menuNumbers {
  font-family: "SofiaPro-Light";
  font-weight: lighter;
}

.addressInfo {
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 22%;
  left: 17.8%;
}

.adressBackgroundPhoto {
  width: 100%;
}
.addressTextContainer {
  position: fixed;
  margin-left: 0.5%;
}
.address {
  font-size: 20px;
  font-family: "SofiaPro-Black";
  margin: 0;
  color: #000;
}

.addressText {
  font-size: 17px;
  font-family: "SofiaPro-Regular";
  margin: 0;
  color: #000;
  text-decoration: none;
}

.backContainer {
  bottom: 5%;
  left: 4%;
  background-color: transparent;
  width: 200px;
  align-items: center;
  display: flex;
  z-index: 70;
  text-decoration: none;
  position: absolute;
  cursor: pointer;
}

.backText {
  font-family: "SofiaPro-Medium";
  font-size: 16px;
  color: #000;
}

.menuLinksContainer {
  display: flex;
  flex-direction: column;
  margin-left: 16.5%;
  margin-top: 20vh;
}

.linkItem {
  text-decoration: none;
  color: #000;
}

.hrLine {
  display: none;
  width: 100%;
}

.leftArrowIcon {
  object-fit: contain;
  width: 25px;
  margin-right: 10px;
}

@media only screen and (max-width: 1200px) {
  .addressInfo {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .NavBarShow {
    background-image: none;
  }
  .navClass {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
  }

  .logo {
    margin-left: -30px;
    width: 66px;
    height: 56px;
    margin-top: -5px;
  }
  .menuLinksContainer {
    font-family: "Sofia Pro, Regular";
    font-size: 33px;
    margin-top: 33vh;
  }
  .menuLinks {
    font-size: 33px;
    align-items: left;
    margin-left: -10%;
    padding: 5px;
  }
  .addressInfo {
    display: none;
  }
  .backContainer {
    bottom: 18%;
    left: 10%;
  }
  .backText {
    font-size: 16px;
  }
  .menuNumbers {
    font-family: "SofiaPro-Light";
    font-weight: lighter;
  }
  .linkItem {
    margin-bottom: 0;
  }
  .hrLine {
    display: inline;
    position: absolute;
    bottom: 12%;
    border-bottom: 1px solid #e4e4e4;
    margin-left: 0%;
  }
  .NavBar {
    width: calc(100% - 180px);
    padding: 30px 50px;
    background: transparent;
    top: 0;
    width: 100%;
    z-index: 400;
    padding: 0;
    position: absolute;
  }
}

@media only screen and (max-width: 370px) {
  .menuLinksContainer {
    font-family: "Sofia Pro, Regular";
    font-size: 33px;
    margin-top: 28vh;
  }
}

@media only screen and (max-width: 350px) {
  .NavBarShow {
    background-image: none;
  }
  .logo {
    margin-left: -30px;
    width: 66px;
    height: 56px;
    margin-top: -5px;
  }
  .menuLinksContainer {
    margin-left: 15%;
    margin-top: 55%;
  }
  .menuLinks {
    font-size: 30px;
    align-items: left;
    height: 45px;
    margin-left: -10%;
    padding: 5px;
  }
  .addressInfo {
    display: none;
  }
  .backContainer {
    bottom: 10%;
    left: 7%;
  }
  .backText {
    font-size: 16px;
  }
  .menuNumbers {
    font-family: "SofiaPro-Light";
    font-weight: lighter;
  }
  .linkItem {
    margin-bottom: 0;
  }
  .hrLine {
    display: inline;
    position: absolute;
    bottom: 10%;
    border-bottom: 1px solid #e4e4e4;
    margin-left: -15%;
  }
  .NavBar {
    width: calc(100% - 180px);
    padding: 30px 50px;
    background: transparent;
    top: 0;
    width: 100%;
    z-index: 400;
    padding: 0;
    position: absolute;
  }
}

@media only screen and (max-height: 900px) {
  .addressInfo {
    left: 49.3% !important;
    top: 53%;
  }
}

.servicescont{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background-image: url('../../assets/porsche/crazy/bg_06@2x.jpg');
    background-size: contain;
  }
.bigText{
    width: 100%;
    display: inline-block;
    height: 100px;
    border: #242424 1px solid;
    display: flex !important;
    border-radius: 10px;
    justify-content: space-around;
    align-items: center;
}
.imazh{
    height: 70%;
}
.desc{
    margin-left: -5%;
}
.smalldesc{
    width: 180px;
}
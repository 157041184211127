.formGreeting {
  padding: 0px 83px 35px 83px;
}

/* .socialButtonsMobile,
.formGreetingMobile,
.greeatingImgMobile {
  display: none;
} */

.infoContact {
  display: flex;
  gap: 20px;
}

.infoContactLocation {
  padding-left: 21px;
  width: 47%;
}

.infoContactLocation .title {
  color: #58c2d7;
  font-weight: 100;
  text-transform: uppercase;
}

.text {
  font-size: 16px;
  font-family: "Montserrat-Light";
  line-height: 24px;
  margin: 2px 0px;
  font-weight: 100;
}

.verticalLine {
  border: 2px solid #58c2d7;
  max-height: 60px;
  margin-top: 10px;
  background-color: #58c2d7;
}

.infoContactLocation {
  padding-left: 21px;
  width: 47%;
}

.infoContactLocation .title {
  color: #58c2d7;
  font-weight: 100;
  text-transform: uppercase;
}

.title {
  font-size: 14px;
  font-family: "SofiaPro-Semibold";
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 0;
}

.input2page {
  background-color: transparent;
  border: 1px solid rgba(225, 225, 225, 0.2);
  padding: 16px;
  line-height: 1.2;
  color: #ffffff91;
  outline: none;
  border-radius: 2px;
  /* width: fit-content; */
  width: 100%;
}

.contactFormContent form p {
  font-size: 13px;
  font-family: "SofiaPro-Regular";
  line-height: 24px;
  margin-bottom: 3px;
}

.contactFormContent {
  padding: 47px 83px 60px;
}

@media only screen and (max-width: 850px) {
  .formGreeting {
    order: 3;
    display: none;
    padding: 30px;
  }

  /* .formGreeting,
  .backgroundImg {
    display: none;
  } */

  .socialButtonsMobile,
  .formGreetingMobile {
    display: block;
  }

  .infoContactCel,
  .infoContactDay {
    width: 100%;
  }

  .infoContactLocation {
    border-left: none;
    padding: 18px 30px;
    width: 70%;
    background-color: #58c2d7;
    position: relative;
    top: -64px;
  }

  .infoContactLocation .title {
    color: #ffffff;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0;
  }

  .infoContact {
    flex-direction: column;
    padding: 0px 30px 0px 30px;
  }

  .infoContact {
    gap: 0;
  }

  .text img {
    padding-right: 4px;
  }

  .text {
    font-size: 13px;
    font-family: "Montserrat-Light";
    line-height: 20px;
    margin: 2px 0px;
  }

  .infoContactLocation {
    border-left: none;
    padding: 18px 30px;
    width: 70%;
    background-color: #58c2d7;
    position: relative;
    top: -64px;
  }

  .infoContactLocation .title {
    color: #ffffff;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0;
  }

  .contactFormContent {
    padding: 0px 30px 0px 30px;
    border: none;
  }

  .contactFormContent h4 {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .msgCelContact {
    width: 70%;
  }

  .msgCelContact p {
    font-size: 16px;
    font-family: "Montserrat-Light";
    font-weight: 100;
    line-height: 24px;
  }

  .msgCelContact p img {
    margin-right: 7px;
  }
}

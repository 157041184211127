.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin-top: 160px;
  margin-right: 60px;
}

.outerCircle {
  width: 120px;
  height: 118px;
  border-radius: 50%;
  scale: 1;
  border: 1px solid #d3d3d3;
  display: block;
  margin-top: -140px;
  margin-right: -30px;
  font-family: 'SofiaPro-Light';
  opacity: 0.2 !important;
}

.menuText {
  text-decoration: none;
  font-size: 12px;
  font-family: 'SofiaPro-Light';
  color: #fff;
  margin-right: -15px;
  margin-bottom: 140px;
  z-index: 40;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.innerContainer {
  display: inline-flex;
  margin-left: -120px;
  margin-top:18px;
  rotate: 0;
  z-index: 2;
}

.innerCircle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  align-self: center;
  z-index: 10;
  margin-left: -5px;
}

.close {
  width: 25px;
  height: 25px;
  background-image: url("../assets/icons/close.png");
  position: absolute;
  background-size: contain;
  right: 75px;
  margin-top:20px;
}

@media only screen and (max-width: 800px) {
  .close {
    display: none;
  }
  .container {
    z-index: 2;
  }

  .outerCircle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    scale: 1;
    border: 1px solid #d3d3d3;
    margin-bottom: 65px;
    margin-right: 9px;
    opacity: 0.3 !important;
  }

  .menuText {
    font-size: 9px;
    margin-top: -63px;
    margin-right: -10px;
  }

  .innerContainer {
    display: inline-flex;
    /* margin-left: -65px; */
    margin-bottom: 60px;
    margin-right: -20px;
    rotate: 0;
    z-index: 2;
  }
  .innerCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
  }
  
  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    align-self: center;
    z-index: 10;
    margin-left: -5px;
  }
}
/* @media only screen and (max-width: 360px) {
  .container{
    position: relative;
  }
  
} */

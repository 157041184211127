.item{
    background-color: rgb(255, 255, 255);
    display: flex;
    width: 20%;
    height: 33vh;
    position: relative;
    align-items: flex-end;
    justify-content: center;
}


.blogu{
    color: #000;
    font-size: 115px;
    font-family: 'SofiaPro-Bold';
    opacity: 0.05;
    letter-spacing: 0px;
    margin: 0;
    line-height: 0.8;
    padding-left: 25px;
}

    @media only screen and (max-width: 768px)  {
.item{
        background-color: transparent;
        height: 14vh;
}
.blogu{
        display: none;
 }
}
    @media only screen and (min-width: 769px) and (max-width: 1200px) {

.item{
     display: flex;
     top: 0px;
     width: 33.33%;
     height: 33vh;
     /* UI Properties */
     opacity: 1;
     cursor: pointer;
     position: relative;
}
    
      }
      @media only screen and (min-width: 1250px) and (max-width: 1500px) {
        .blogu{
            color: #000;
            font-size: 115px;
            font-family: 'SofiaPro-Bold';
            opacity: 0.05;
            letter-spacing: 0px;
            margin: 0;
            line-height: 0.8;
            padding-left: 0;
        }
      }
      @media only screen and (min-width: 1200px) and (max-width: 1500px) {
        .item{
            display: flex;
            top: 0px;
            width: 25%;
            height: 33vh;
            /* UI Properties */
            opacity: 1;
            cursor: pointer;
            position: relative;
       }
    }
.terms {
    display: none;
    text-align: right;
    font-size: 12px;
    font-family: "SofiaPro-Regular";
  }
  .text {
    color:#ffffffb8;
  }
  .texti{
      color: #ffffffb8;
  }
   .footeri{
      padding-top: 35px;
      padding-right: 110px;
  } 
  .footeri .Footer_footer__2D2sM{

    justify-content: unset;
}
.footeri .Footer_footer_container__27vJn{
    max-width: 100%;
    background-size: 100%;
    padding-bottom: 5px;
}

.footeri  .Footer_terms__1rUkm{
    padding-right: 15px;
}
@media only screen and (max-width: 800px){
 .terms{
    display: flex;
    background-color: #101010;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    /* letter-spacing: 1px; */
}
.texti{
    color: #ffffffb8;
    padding-left: 5px;
    padding-right: 5px;
}
.footeri .Footer_footer__2D2sM{
    width: 95%;
   
}
.footeri .BlogItem_footeri__3liLE{
    padding-top: 18px;
}
.footeri{
    padding-right: 0;
}
.footeri .Footer_footer_container__TAc\+3{
    margin: 0px;
    padding: 0px 10px 0px 20px;
    background-size: 368px;
}
.footeri .Footer_footer_container__27vJn > div{
    padding-left: 20px;
}
.footeri .Footer_logo__33MdQ{
    padding-left: 20px;
}
.footeri .Footer_h3__307k-{
    margin-left: 0;
}
}
.textlight{
    color: white;
    line-height: normal;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 56px;
    padding-bottom: 50px;
    font-size: 18px;
    
}
.cocacolared{
    background-color: red;
    height: max-content;
    min-height: 100vh;
    
}
.firstpg{
    line-height: 1;
    
}

@media screen and (max-width: 768px) {
    .cocacolared{
        height: max-content;
    }
}
.container {
  display: flex;
  flex: 1;
  background-color: #1a1a1a;
  margin-top: -35px;
  flex-direction: column;
  padding-top: 120px;
  position: relative;
}

.backgroundContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/about-crazy/HOME.jpg");
  justify-content: center;
  margin-top: -88px;
}

.peopleImgDesktop{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.headerTitle {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: "SofiaPro-Light";
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -23vh;

}

.headerTitle strong {
  font-family: "SofiaPro-Bold";
}

.leftTextContainer {
  display: flex;
  width: 50%;
  justify-content: center;
}

.leftTextContainer p{
  color: rgb(255, 255, 255);
  font-family: SofiaPro-Bold;
  font-size: 22px;
  padding-left: 20px;
  max-width: 400px;
  border-left-color: #54bacd;
  border-left-width: 3px;
  border-left-style: solid;
  line-height: 40px;
  height: 87px;
  width: 29% !important;
}

.secondContainer {
  display: flex;
  color: rgb(255, 255, 255);
  font-family: SofiaPro-Bold;
  font-size: 22px;
  padding-left: 20px;
  width: 100%;
  margin-top: 100px !important;
}

.rightTextContainer {
  display: flex;
  width: 50%;
}

.rightTextContainer > div{
  padding-left: 60px;
}

.rightTextContainer > div:nth-child(2){
  padding-left: 100px;
}

.rightTextContainer p{
  color: rgb(255, 255, 255);
  font-family: SofiaPro-light;
  font-size: 22px;
  opacity: 0.7;
}

.mainText {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: SofiaPro-Light;
  position: relative;
  background-color: #1a1a1a;
  margin: 0;
  font-weight: 400;
  padding-bottom: 65px;
}

.greenColor.mainText {
  font-family: SofiaPro-SemiBold;
  color: #54bacd;
  padding-bottom: 0 !important;
  padding-top: 50px !important;
}

.thirdContainer {
  margin-top: 235px !important;
}

.biggerContainer{
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1154px;
  display: flex;
  align-items: center;
}
.pContainer{
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
}
.partnerContainer{
  width: 193px;
  height: 130px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnerLogoDesktopCrazy{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.logoImgDesktopCrazy{
  max-width: 100px;
  width: 100%;
}
.whiteContainerDesktopCrazy{
  padding: 0px 300px;
}
.whiteContainer{
  display: flex;
  align-items: center;
}

.partnerLogo:nth-child(9){
  filter: brightness(0) invert(1) !important;
}
.partnerLogo:nth-child(2){
  filter: brightness(0) invert(1) !important;
}
.partnerLogo:nth-child(5){
  filter: brightness(0) invert(1) !important;
}

.mobileLogo:nth-child(3){
  filter: brightness(0) invert(1) !important;
}

.projectsContainer {
  width: 90%;
  height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  padding-top: 40px;
  margin-top: 250px;
  position: relative;
  z-index: 1;
  background-color: #1a1a1a;
}


.secondLine2{
  width: 1px;
  min-height: 7%;
  max-height: 7%;
  border-left: 1px solid #ffffff3d;
  left: 50%;
  position: absolute;
  z-index: 0;
  margin-top: 5%;
}
.projectLogo {
  object-fit: contain;
  width: 200px;
  height: 200px;
  transition: 0.5s ease;
  cursor: pointer;
}

.discoverButton {
  background-color: transparent;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #2b2b2b;
  cursor: pointer;
  padding: 0px 10px;
}

.discoverButton p{
  margin: 13px 10px;
}

.discoverButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;
  z-index: 1;
  background-color: #1a1a1a;
}

.discoverButtonContainer p{
  color: rgb(255, 255, 255);
  font-family: SofiaPro-Light;
  font-size: 28px;
  font-weight: 400;
}

.formContainer {
  background-color: #1a1a1a;
  max-width: 380px;
  height: 51px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #2b2b2b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input {
  margin: 0;
  flex: 1 1;
  border-width: 0;
  font-family: SofiaPro-Light;
  font-size: 14px;
  background-color: #1a1a1a;
  margin-left: 10px;
  font-family: SofiaPro-Light;
  font-size: 16px;
}

.input::placeholder {
  font-family: SofiaPro-Light;
  font-size: 16px;
}

.submitButton {
  border-width: 0;
  height: 45px;
  background-color: transparent;
  margin-right: 10px;
  color: #fff;
  font-family: SofiaPro-Light;
  font-size: 16px;
}

.contactContainer {
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex: 1;
  margin-top: 320px !important;
  align-items: center;
  justify-content: space-between;
  z-index: 999999;
  align-content: flex-end;
}
.videoContainer{
  height: 50%;
}
.rowContainer {
  flex: 1;
  z-index: 99999;
}

.slick-slider{
  width: 100%;
}

.slick-slide > div{
  margin: 0;
  padding: 0;
}

.text {  
  font-family: SofiaPro-Light;
  font-size: 18px;
  color: #fff;
  font-weight: 100;
  line-height: 30px;
  margin: 0;
}

.privacyText {
  font-family: SofiaPro-Light;
  font-size: 16px;
  color: #fff;
  margin-top: 40px;
  font-weight: 100;
}

.subscribeText{
  font-family: "SofiaPro-Medium";
  font-size: 22px;
  color: #fff;
  font-weight: 400;
  margin-left: 52px;
}

.infoContact{
  margin-top: 70px;
}

.copyright{
  margin-top: 75px;
}

.aboutContainer {
  display: flex;
  z-index: 99999;
}

.backgroundFooter {
  width: 100%;
  object-fit: contain;
  /* margin-top: -16%; */
  z-index: 0;
  /* margin-bottom: -84px; */
}
.videoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -22% !important;
}


@media screen and (min-width: 1600px) and (min-height:950px) and (max-height:1080px){
  .contactContainer {
    margin-top: 200px;
  }

  .projectsContainer {
    margin-top: 380px;
  }

  .secondContainer {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (max-height: 920px) {
  .backgroundContainer h1{
    margin-top: 18vh;
  }
  .verticalLine{
    display: none;
  }

  .backgroundContainer {
    height: 58vh;
    width: 100%;
    background-size: contain;
    background-position: bottom;
    margin-bottom: 80px;
    background-image: url('../../assets/about-crazy/Home_mobile.png');
    display: flex;
    flex-direction: column;
    margin-top: -3%;
  }

  .peopleImg{
    margin-top: -380px;
  }
  .headerTitle {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-family: "SofiaPro-Light";
    font-weight: 400;
  }

  .rightTextContainer p{
    font-size: 18px;
  }

  .rowContainer {
    flex: 1;
    width: 100%;
  }

  .text {  
    font-size: 14px;
    line-height: 25px;
  }

  .secondContainer{
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    margin-top: -8% !important;
  }

  .rightTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .thirdContainer {
    margin-top: 12px !important;
  }

  .leftTextContainer {
    margin-bottom: 20px;
  }

  .rightTextContainer > div{
    padding-left: 60px;
  }
  
  .rightTextContainer > div:nth-child(2){
    padding-left: 60px;
  }

  .subscribeText{
    font-size: 20px;
  }

  .partnersContainer {
    padding-top: 20px;
  }

  .privacyText {
    margin-top: 15px;
    margin-bottom: 50px;
    display: none;
  }

  .infoContact{
    margin-top: 30px;
  }
  
  .copyright{
    margin-top: 45px;
  }

  .discoverButtonContainer {
    margin-bottom: 50px;
  }

  .leftTextContainer p {
    color: rgb(255, 255, 255);
    font-family: SofiaPro-Bold;
    font-size: 18px;
    padding-left: 15px;
    max-width: 290px;
    line-height: 1.4;
    border-left-width: 7px;
    height: 57px;
    width: 61% !important;
    margin-left: -15%;
  }

  .leftTextContainer {
    width: 100%;
    margin-top: -40px;
  }

  .mainText {
    font-size: 18px;
  }

  .partnerLogo {
    width: 100%;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .partnersContainer {
    grid-gap: 2rem 1rem;
    grid-gap: 3rem 2rem;
    gap: 3rem 2rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .discoverButtonContainer p{
    font-size: 25px;
  }

  .contactContainer{
    margin-top: 50px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: revert;
    padding: 0;
    width: 87%;
    margin: 0 auto;
    margin-top: 0px !important;
  }

  .projectsContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    padding-top: 40px;
    margin-top: 250px;
    display: flex;
    margin-top: 50px;
  }

  .projectLogo {
    object-fit: cover;
    width: 50%;
    height: auto;
    margin-right: 0px;
  }
  .socials{
    display: none;
  }
  .aboutContainer{
    display: none;
  }
  .socialIcons img{
    font-size: 20px;
    align-self:center;
    margin: 0 auto;
  }
  .socialIcons{
    text-align:center;
	  float: left;
	  width: 27px;
	  height: 27px;
	  border: 1px solid #9b9898;
	  border-radius: 100%;
	  margin-right: 7px; /*space between*/
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
 
  .backgroundFooter{
    margin-bottom: -313px;
  }

  .videoContainer{
    margin-top: 0px !important;
  }
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}
 
@media screen and (max-width: 365px) {
  .peopleImg{
    margin-top: -323px !important;
  }
  .leftTextContainer{
    margin-top: -24px !important;
  }
  .secondContainer{
    margin-top: 0% !important;
  }
  .thirdContainer{
    margin-top: 15px !important;
  }
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }

}
@media screen and (max-width: 376px) and (min-width:370px) {
  .peopleImg{
    margin-top: -230px !important;
  }
  .secondContainer{
    margin-top: 11% !important;
  }
  .leftTextContainer{
    margin-top: -24px !important;
  }

  .thirdContainer{
    margin-top: 45px !important;
  }
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}
@media screen and (max-width: 380px) and (min-width:377px) {
  .peopleImg{
    margin-top: -323px !important;
  }
  .leftTextContainer{
    margin-top: -24px !important;
  }

  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}

@media screen and (max-height:850px) and (min-height:840px) {

  .thirdContainer {
    margin-top: 25px !important;
  }

  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}

@media screen and (max-width:415px) and (min-width:400px) {
  .secondContainer{
    margin-top:0% !important;
  }  
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}

@media screen and (max-width:330px) and (min-width:300px) {
  .container{
    margin-top: 25px;
  }
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}

@media screen and (min-width:1925px) and (min-width:1900px) {
  .backgroundContainer h1{
    margin-top: -24px !important;
  }
  .mobileLogo:nth-child(3){
    filter: brightness(0) invert(1) !important;
  }
}